import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';

import Drawer from './Drawer';
import SurfaceList from './SurfaceList';

import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  customColorCodeInputWrapper: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));


const SurfaceDrawer = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyle();
  const {
    activeSurface,
    customColorCode,
    extra,
    handleClose,
    mediaHost,
    open,
    setCustomColorCode,
    setExtra,
    setSurface,
    surfaces,
  } = props;

  const [customColorCodeError, setCustomColorCodeError] = useState(false);

  const handleSurfaceClose = () => {
    if (activeSurface.customColor) {
      if (customColorCode === '') {
       setCustomColorCodeError(true)
      } else {
        setCustomColorCodeError(false)
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (customColorCode === '') {
      setCustomColorCodeError(true) 
     } else {
       setCustomColorCodeError(false) 
     }
  }, [customColorCode]);

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleSurfaceClose}
      header={t('Oberfläche')}
    >
      <SurfaceList
        activeSurface={activeSurface}
        extra={extra}
        mediaHost={mediaHost}
        setExtra={setExtra}
        setSurface={setSurface}
        surfaces={surfaces}
      />

      {activeSurface.customColor && (
        <div className={classes.customColorCodeInputWrapper}>
          <TextField
            fullWidth
            label={t('Farbcode und Farbwertname')}
            value={customColorCode}
            onChange={(evt) => setCustomColorCode(evt.target.value)}
            error={customColorCodeError}
            helperText={customColorCodeError ? t('Farbcode und Farbwertname eingeben') : ''}
          />
        </div>
      )}
    </Drawer>
  );
};

export default SurfaceDrawer;
