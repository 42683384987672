import React, { useContext, useEffect, useState } from 'react';

import 'url-search-params-polyfill';

import { useQuery } from '@apollo/client';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CropLandscapeOutlinedIcon from '@material-ui/icons/CropLandscapeOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import Paper from '@material-ui/core/Paper';
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LockIcon from '@material-ui/icons/Lock';
import StarIcon from '@material-ui/icons/Star';

import { makeStyles } from '@material-ui/styles';
import { useAlert } from 'react-alert';

import { CartContext } from '../contexts/CartContext';
import { CONFIGURATOR_QUERY } from '../lib/queries';

import EngravingDrawer from './EngravingDrawer';
import Frame from './Frame';
import IndividualInlayDrawer from './IndividualInlayDrawer';
import Loader from './Loader';
import Products from './Products';
import ShippingDialog from './ShippingDialog';
import StandardInlayDrawer from './StandardInlayDrawer';
import SurfaceDrawer from './SurfaceDrawer';

import { localeProps, string_to_slug } from '../lib/utils';

import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  '@global': {
    'body': {
      backgroundColor: 'white !important',
    },
  },


  carDataWrapper: {
    marginTop: 30,
  },  
  
  cartButton: {
    borderRadius: 0,
    marginTop:0,
    paddingLeft: 8,
    paddingRight: 8,
    height: 70,
    fontWeight:300,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  
  cartIcon: {
    marginRight: 10,
  },

  divider: {
    width: 40,
    height: 1,
    backgroundColor: theme.colors.orange,
    marginTop:20,
  },

  errorPaper: {
    backgroundColor: theme.palette.error.light,
    padding: 20,
  },

  footerBlock: {
    textAlign: 'center',
  },

  footerBorderRight: {
    borderRight: '1px solid #666666',

    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },

  footerHeadline: {
    color: theme.colors.orange,
    textTransform: 'uppercase',
    fontSize: 14,
  },

  footerIcon: {
    color: theme.colors.orange,
  },

  footerText: {
    paddingTop: 5,
    fontSize: 12,
  },

  frameDisabled: {
    color: 'grey !important',
    
    '& img': {
      opacity: 0.4,
    },
    
    '& span': {
      color: 'grey !important',
    },

    '& svg': {
      color: 'grey !important',
    },
  },

  frameDisabledOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  frameRear: {
    marginTop: 30,
  },

  frameSelector: {
    color: theme.colors.orange,
    textTransform: 'uppercase',
  },

  frameSelectorDisabled: {
    color: 'grey !important',
  },

  frameSettings: {
    flexBasis: 340,
    width: 340,
    boxSizing: 'border-box',
    paddingLeft: 40,
  },

  frameWrapper: {
    position: 'relative',
    flexDirection: 'row',
  },

  headline: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 28,
    fontWeight: 400,
  },

  headlineLight: {
    color: '#666666',
  },
  
  input: {
    marginTop: 8,
    marginBottom: 8,
    color: theme.colors.darkGrey,
    fontWeight: 400,

    '& label': {
      color: theme.colors.darkGrey,
      fontWeight: 400,
    },

    '& div': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },

  listItem: {
    backgroundColor: theme.colors.lightGrey,
    marginBottom: 10,
  },

  mainContent: {
    position: 'relative',
    overflow: 'hidden',
    minHeight:800,
  },

  priceInfo: {
    fontSize: 13,
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
  },

  productsDesktop: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  productsMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  productsWrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    padding: '0px 10px',
    alignItems: 'center',
  },

  selectedSurface: {
    paddingTop: 10,
  },

  spacer: {
    backgroundColor: theme.colors.orange,
    height: 1,
    width: 50,
    margin: '30px auto 30px',
  },

  subHeadline: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 400,
    color: '#666666',
    marginBottom: 30,
  },

  summary: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 60,
    padding: '20px 10px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  surfacePreview: {
  },

  surfacePreviewImage: {
    width: 100,
    maxWidth: '100%',
  },
  
  switch: {
    verticalAlign: 'middle',
    marginRight: 10,
  },

  total: {
    color:theme.colors.orange,
    fontSize: 32,
    marginBottom:30,
    fontWeight: 400,
  },
}));

const ConfigureCarsign = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const alert = useAlert();
  const classes = useStyles();
  const { loading, error, data } = useQuery(CONFIGURATOR_QUERY);
  const { addProduct, openCartDrawer, mediaHost } = useContext(CartContext);
  
  const [carType, setCarType] = useState('');
  const [engravingExtra, setEngravingExtra] = useState(undefined);
  const [engravingPrice, setEngravingPrice] = useState(undefined);
  const [fonts, setFonts] = useState(undefined);
  const [frontChecked, setFrontChecked] = useState(false);
  const [frontCustomColorCode, setFrontCustomColorCode] = useState('');
  const [frontExtra, setFrontExtra] = useState('none');
  const [frontPrice, setFrontPrice] = useState(0.0);
  const [frontSurface, setFrontSurface] = useState(undefined);
  const [frontSurfaceDrawerOpen, setFrontSurfaceDrawerOpen] = useState(false);
  const [individualInlayPrice, setIndividualInlayPrice] = useState(undefined);
  const [inlayArts, setInlayArts] = useState(undefined);
  const [inlayBackgrounds, setInlayBackgrounds] = useState(undefined);
  const [inlayCategories, setInlayCategories] = useState(undefined);
  const [inputErrors, setInputErrors] = useState({});
  const [manufacturer, setManufacturer] = useState('');
  const [model, setModel] = useState('');
  const [modelVariant, setModelVariant] = useState('');
  const [price, setPrice] = useState(0.0);
  const [rearChecked, setRearChecked] = useState(false);
  const [rearCustomColorCode, setRearCustomColorCode] = useState('');
  const [rearEngraving, setRearEngraving] = useState(undefined);
  const [rearEngravingDrawerOpen, setRearEngravingDrawerOpen] = useState(false);
  const [rearExtra, setRearExtra] = useState('none');
  const [rearIndividualInlay, setRearIndividualInlay] = useState(undefined);
  const [rearStandardInlayDrawerOpen, setRearStandardInlayDrawerOpen] = useState(false);
  const [rearIndividualInlayDrawerOpen, setRearIndividualInlayDrawerOpen] = useState(false);
  const [rearPrice, setRearPrice] = useState(0.0);
  const [rearSurface, setRearSurface] = useState(undefined);
  const [rearSurfaceDrawerOpen, setRearSurfaceDrawerOpen] = useState(false);
  const [rearStandardInlay, setRearStandardInlay] = useState(undefined);
  const [setDiscount, setSetDiscount] = useState(undefined);
  const [shippingDialogOpen, setShippingDialogOpen] = useState(false);
  const [standardInlayPrice, setStandardInlayPrice] = useState(undefined);
  const [surfaces, setSurfaces] = useState(undefined);
  const [yearBulild, setYearBulild] = useState('');

  useEffect(() => {
    if (loading === false && data) {
        setEngravingExtra(data.engravingExtra);
        setEngravingPrice(data.engravingPrice);
        setFonts(data.fonts);
        setFrontChecked(data.showFront);
        setFrontSurface(data.surfaces[0]);
        setIndividualInlayPrice(data.individualInlayPrice);
        setInlayArts(data.inlayArts);
        setInlayBackgrounds(data.inlayBackgrounds)
        setInlayCategories(data.inlayCategories);
        setRearChecked(data.showRear);
        setRearEngraving({
          text: '',
          font: data.fonts[0],
        });
        setRearIndividualInlay({
          text: '',
          font: data.fonts[0],
          background: data.inlayBackgrounds[0],
          art: undefined,
          fontColor: 'black',
        });
        setRearSurface(data.surfaces[0]);
        setSetDiscount(data.setDiscount);
        setStandardInlayPrice(data.standardInlayPrice);
        setSurfaces(data.surfaces);

        const locationSearch = new URLSearchParams(window.location.search);
        if (locationSearch.has("cm")) {
          setManufacturer(locationSearch.get("cm"));
        }
    }
  }, [loading, data]);


  useEffect(() => {
    if (frontSurface && frontExtra) {
      let newFrontPrice = 0.0;
      if (frontChecked === true) {
        newFrontPrice += parseFloat(frontSurface.price);

        if (frontExtra !== 'none') {
          newFrontPrice += parseFloat(engravingExtra);
        }
      }
      setFrontPrice(newFrontPrice);
    }
  }, [frontChecked, frontSurface, frontExtra]);

  useEffect(() => {
    if (rearExtra !== 'engraving') {
      setFrontExtra('none');
    }
  }, [rearExtra]);
  
  useEffect(() => {
    if (rearSurface && rearExtra) {
      let newRearPrice = 0.0;
      if (rearChecked === true) {
        newRearPrice += parseFloat(rearSurface.price);

        if (rearExtra === 'engraving') {
          newRearPrice += parseFloat(engravingPrice);
        }

        if (rearExtra === 'standard-inlay') {
          newRearPrice += parseFloat(standardInlayPrice);
        }

        if (rearExtra === 'individual-inlay') {
          newRearPrice += parseFloat(individualInlayPrice);
        }
      } else {
        setFrontExtra('none');
      }

      setRearPrice(newRearPrice);
    }
  }, [rearChecked, rearSurface, rearExtra, individualInlayPrice, standardInlayPrice]);

  useEffect(() => {
    let discount = parseFloat(0.0);
    // check it is is a set
    if (frontSurface && rearSurface && frontChecked && rearChecked) {
      discount = setDiscount;
    }
    setPrice(frontPrice + rearPrice - discount);

  }, [frontPrice, rearPrice]);

  const validateInputFields = () => {
    let fieldsValid = true;
    const newInputErrors = {};
    if (manufacturer.trim() === '') {
      newInputErrors.manufacturer = true;
      fieldsValid = false;
    }
    if (model.trim() === '') {
      newInputErrors.model = true;
      fieldsValid = false;
    }
    if (yearBulild.trim() === '') {
      newInputErrors.yearBulild = true;
      fieldsValid = false;
    }

    setInputErrors(newInputErrors);

    return fieldsValid;
  };

  const handleAddCart = () => {
    if (validateInputFields()) {
      let extraData;
      let frameDeliveryTime;
      
      // generate name
      let carsignName = `Ihr CarSign® für ${manufacturer} ${model}`;
      if (carType) {
        carsignName += ` ${carType}`
      }
      if (modelVariant) {
        carsignName += ` / ${modelVariant}`
      }
      carsignName += ` (${yearBulild}) / EU`
      
      // set extra-data
      if (rearExtra === 'engraving') {
        extraData = {
          fullData: rearEngraving,
          text: rearEngraving.text,
          fontName: rearEngraving.font.name,
          color: '',
          art: '',
          background: '', 
          inlay: '',
        };
      } else if (rearExtra === 'standard-inlay') {
        extraData = {
          fullData: rearStandardInlay,
          text: '',
          fontName: '', 
          color: '',
          art: '',
          background: '', 
          inlay: rearStandardInlay.name,
        };
      } else if (rearExtra === 'individual-inlay') {
        extraData = {
          fullData: rearIndividualInlay,
          text: rearIndividualInlay.text,
          fontName: rearIndividualInlay.font.name,
          color: rearIndividualInlay.color,
          art: rearIndividualInlay.art ? rearIndividualInlay.art.name : '',
          background: rearIndividualInlay.background.name,
          inlay: '',
        };
      } else {
        extraData = {
          fullData: undefined,
          text: '',
          fontName: '', 
          color: '',
          art: '',
          background: '', 
          inlay: '',
        };
      }

      // calculate delivery time
      if (frontSurface.deliveryTime) {
        frameDeliveryTime = frontSurface.deliveryTime;
      }
      if (rearSurface.deliveryTime) {
        if (frameDeliveryTime) {
          if (rearSurface.deliveryTime.lenghInDays > frameDeliveryTime.lenghInDays ) {
            frameDeliveryTime = rearSurface.deliveryTime;
          } 
        } else {
          frameDeliveryTime = rearSurface.deliveryTime;
        }
      }

      // add product to cart
      let newProduct = {
        type: 'carsign',
        name: carsignName,
        car: {
          carType,
          manufacturer,
          model,
          modelVariant,
          yearBulild,
        },
        extraData,
        front: {
          checked: frontChecked,
          extra: frontExtra,
          surface: frontSurface,
          customColorCode: frontCustomColorCode,
        },
        price: Number(price),
        quantity: 0,
        rear: {
          checked: rearChecked,
          extra: rearExtra,
          surface: rearSurface,
          customColorCode: rearCustomColorCode,
        },
        deliveryTime: frameDeliveryTime,
        idString: '',
      }
      newProduct.idString = JSON.stringify(newProduct);
      newProduct.quantity = 1;
      newProduct.sku = string_to_slug(newProduct.idString);

      addProduct(newProduct);
      openCartDrawer();
    }
  };
  
  if (error) {
    return (
      <Paper elevation={3} className={classes.errorPaper}>
        <h1>{t('Fehler')}</h1>
        <p>{t('Die Konfigurator-Daten konnten leider nicht geladen werden')}.</p>
        <p>{t('Bitte versuchen Sie es später erneut')}.</p>
      </Paper>
    );
  }

  if (frontSurface && rearSurface && surfaces && fonts) {
    return (
      <>
        <Grid container spacing={6} className={classes.header}>
          <Grid item xs={12} md={8}>
            <div className={classes.headline}>
              {t('My CarSign')}
              <span className={classes.headlineLight}> {t('Konfigurator')}</span>
              <div className={classes.spacer}></div>
            </div>
            <div className={classes.subHeadline}>
              ({t('EU Kennzeichengröße')}: 520 x 110 mm)
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.total}>
              {isNaN(price)
                ? price
                : Number(price).toLocaleString('de', localeProps)}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={6} className={classes.mainContent}>
          <Grid 
            item
            xs={12}
            md={8}
          >
            <div
              className={
                frontChecked
                  ? classes.frameWrapper
                  : `${classes.frameWrapper} ${classes.frameDisabled}`
              }
            >

              <Frame
                mediaHost={mediaHost}
                engraving={rearEngraving}
                extra={frontExtra}
                individualInlay={undefined}
                standardInlay={undefined}
                surface={frontSurface}
              />

              {frontSurface && (
                <div className={classes.selectedSurface}>
                  {t('Gewählte Oberfläche')}: {frontSurface.name}
                </div>
              )}
            </div>

            <div
              className={
                rearChecked
                ? `${classes.frameWrapper} ${classes.frameRear}`
                : `${classes.frameWrapper} ${classes.frameRear} ${classes.frameDisabled}`
              }
            >
              <Frame
                mediaHost={mediaHost}
                engraving={rearEngraving}
                extra={rearExtra}
                individualInlay={rearIndividualInlay}
                standardInlay={rearStandardInlay}
                surface={rearSurface}
              />

              {rearSurface && (
                <div className={classes.selectedSurface}>
                  {t('Gewählte Oberfläche')}: {rearSurface.name}
                </div>
              )}
              
            </div>

            <div className={classes.productsDesktop}>
              <Products />
            </div>
          </Grid>

          <Grid 
            item
            xs={12}
            md={4}
          >
            <div
              className={
                frontChecked
                  ? classes.frameWrapper
                  : `${classes.frameWrapper} ${classes.frameDisabled}`
              }
            >
              <FormControlLabel
                className={
                  frontChecked
                    ? classes.frameSelector
                    : `${classes.frameSelector} ${classes.frameSelectorDisabled}`
                }
                control={
                  <Switch
                    checked={frontChecked}
                    onChange={() => setFrontChecked(!frontChecked)}
                    name="frontChecked"
                    color="primary"
                  />
                }
                label={t('Fahrzeug Front')}
              />
              <List component="nav" aria-label="main mailbox folders">
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => setFrontSurfaceDrawerOpen(true)}
                >
                  <ListItemIcon>
                    <CropLandscapeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('surface', 'Oberfläche...')} />
                </ListItem>
                {
                  frontChecked === false && 
                  <div className={classes.frameDisabledOverlay} />
                }
              </List>
            </div>

            <div className={classes.divider}></div>

            <div
              className={
                rearChecked
                ? `${classes.frameWrapper} ${classes.frameRear}`
                : `${classes.frameWrapper} ${classes.frameRear} ${classes.frameDisabled}`
              }
            >
              <FormControlLabel
                className={
                  rearChecked
                    ? classes.frameSelector
                    : `${classes.frameSelector} ${classes.frameSelectorDisabled}`
                }
                control={
                  <Switch
                    checked={rearChecked}
                    onChange={() => setRearChecked(!rearChecked)}
                    name="rearChecked"
                    color="primary"
                  />
                }
                label={t('Fahrzeug Heck')}
              />
              <List component="nav" aria-label="main mailbox folders">
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => setRearSurfaceDrawerOpen(true)}
                >
                  <ListItemIcon>
                    <CropLandscapeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('surface', 'Oberfläche...')} />
                </ListItem>
                
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => setRearEngravingDrawerOpen(true)}
                >
                  <ListItemIcon>
                    <BorderColorOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('laser_engraving', "Lasergravur...")} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={() => {
                        if (rearExtra === 'engraving') {
                          setRearExtra('none');
                        } else {
                          if (rearEngraving && rearEngraving.text !== '') {
                            setRearExtra('engraving');
                          }
                          setRearEngravingDrawerOpen(true);
                        }
                      }}
                      checked={rearExtra === 'engraving'}
                      inputProps={{ 'aria-labelledby': 'rear engraving' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>

                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => setRearStandardInlayDrawerOpen(true)}
                >
                  <ListItemIcon>
                    <PanoramaOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('standard_inlay', 'Standard-Inlay...')} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={() => {
                        if (rearExtra === 'standard-inlay') {
                          setRearExtra('none');
                        } else {
                          if (rearStandardInlay !== undefined) {
                            setRearExtra('standard-inlay');
                          }
                          setRearStandardInlayDrawerOpen(true);
                        }
                      }}
                      checked={rearExtra === 'standard-inlay'}
                      inputProps={{ 'aria-labelledby': 'rear standard inlay' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>

                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => setRearIndividualInlayDrawerOpen(true)}
                >
                  <ListItemIcon>
                    <PermMediaOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('individual_inlay', 'Individuelles Inlay...')} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={() => {
                        if (rearExtra === 'individual-inlay') {
                          setRearExtra('none');
                        } else {
                          if (
                            rearIndividualInlay !== undefined &&
                            rearIndividualInlay.text.length > 0
                          ) {
                            setRearExtra('individual-inlay');
                          }
                          setRearIndividualInlayDrawerOpen(true);
                        }
                      }}
                      checked={rearExtra === 'individual-inlay'}
                      inputProps={{ 'aria-labelledby': 'rear individual inlay' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                {
                  rearChecked === false && 
                  <div className={classes.frameDisabledOverlay} />
                }
              </List>
            </div>

            <div className={classes.divider}></div>

            <div className={classes.carDataWrapper}>
              <TextField
                size="small"
                variant="filled"
                id="manufacturer-input"
                label={t('Hersteller')}
                value={manufacturer}
                onChange={(evt) => setManufacturer(evt.target.value)}
                fullWidth
                className={classes.input}
                margin="normal"
                error={inputErrors.manufacturer}
                helperText={inputErrors.manufacturer ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
              />

              <TextField
                size="small"
                variant="filled"
                id="model-input"
                label={t('Model')}
                value={model}
                onChange={(evt) => setModel(evt.target.value)}
                fullWidth
                className={classes.input}
                margin="normal"
                error={inputErrors.model}
                helperText={inputErrors.model ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
              />

              <TextField
                size="small"
                variant="filled"
                id="car-type-input"
                label={t('Fahrzeugtyp')}
                placeholder={t('example_car_type', 'z.B. SUV, Coupé, Limousine...')}
                value={carType}
                onChange={(evt) => setCarType(evt.target.value)}
                fullWidth
                className={classes.input}
                margin="normal"
              />

              <TextField
                size="small"
                variant="filled"
                id="model-variant-input"
                label={t('Modelvariante')}
                placeholder={t('example_model', 'z.B. AMG Line, S-Line, M-Pake,...')}
                value={modelVariant}
                onChange={(evt) => setModelVariant(evt.target.value)}
                fullWidth
                className={classes.input}
                margin="normal"
              />

              <TextField
                size="small"
                variant="filled"
                id="year-built-input"
                label={t('Baujahr')}
                placeholder={t('example_year_built', 'z.B. 01/1998')}
                value={yearBulild}
                onChange={(evt) => setYearBulild(evt.target.value)}
                fullWidth
                className={classes.input}
                margin="normal"
                error={inputErrors.yearBulild}
                helperText={inputErrors.yearBulild ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
              />
            </div>

            <div className={classes.productsMobile}>
              <Products />
            </div>
          </Grid>

          <Grid 
            item
            xs={12}
            md={8}
          >
            <Grid container spacing={0} className={classes.header}>
              <Grid 
                item
                xs={12}
                md={4}
                className={classes.footerBlock}
              >
                <div className={classes.footerIcon}>
                  <CheckCircleOutlineIcon fontSize="small" />
                </div>
                <div className={`${classes.footerHeadline} ${classes.footerBorderRight}`}>
                  {t('Höchste Qualität')}
                </div>
                <div className={`${classes.footerText} ${classes.footerBorderRight}`}>
                  {t('Made in Germany')}
                </div>
              </Grid>

              <Grid 
                item
                xs={12}
                md={4}
                className={classes.footerBlock}
              >
                <div className={classes.footerIcon}>
                  <StarIcon fontSize="small" />
                  <StarIcon fontSize="small" />
                  <StarIcon fontSize="small" />
                  <StarIcon fontSize="small" />
                  <StarIcon fontSize="small" />
                </div>
                <div className={`${classes.footerHeadline} ${classes.footerBorderRight}`}>
                  {t('Kundenbewertung 4,8')}
                </div>
                <div className={`${classes.footerText} ${classes.footerBorderRight}`}>
                  {t('Basierend auf 912 Rezensionen')}
                </div>
              </Grid>

              <Grid 
                item
                xs={12}
                md={4}
                className={classes.footerBlock}
              >
                <div className={classes.footerIcon}>
                  <LockIcon fontSize="small" />
                </div>
                <div className={classes.footerHeadline}>
                  {t('Mit Sicherheit')}
                </div>
                <div className={classes.footerText}>
                  {t('ein absolutes Highlight')}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid 
            item
            xs={12}
            md={4}
          >
            <Grid container spacing={0} className={classes.header}>
              <Grid 
                item
                xs={12}
                md={6}
              >
                <Button 
                  variant="contained"
                  color="primary"
                  className={classes.cartButton}
                  onClick={handleAddCart}
                  disabled={isNaN(price)}
                  size="large"
                >
                  {t('In den Warenkorb')}
                </Button>
              </Grid>
              <Grid 
                item
                xs={12}
                md={6}
              >
                <div className={classes.priceInfo}>
                  <div>
                    {t('inkl_mwst', 'inkl_mwst', 'inkl. MwSt. zzgl.')}&nbsp;
                    <span 
                      className="inlineLink"
                      onClick={() => setShippingDialogOpen(true)}
                    > 
                      {t('Versandkosten')}
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>


          <SurfaceDrawer
            activeSurface={frontSurface}
            customColorCode={frontCustomColorCode}
            extra={frontExtra}
            handleClose={() => setFrontSurfaceDrawerOpen(false)}
            mediaHost={mediaHost}
            open={frontSurfaceDrawerOpen}
            setCustomColorCode={setFrontCustomColorCode}
            setExtra={setFrontExtra}
            setSurface={setFrontSurface}
            surfaces={surfaces}
          />

          <SurfaceDrawer
            activeSurface={rearSurface}
            customColorCode={rearCustomColorCode}
            extra={rearExtra}
            handleClose={() => setRearSurfaceDrawerOpen(false)}
            mediaHost={mediaHost}
            open={rearSurfaceDrawerOpen}
            setCustomColorCode={setRearCustomColorCode}
            setExtra={setRearExtra}
            setSurface={setRearSurface}
            surfaces={surfaces}
          />

          <EngravingDrawer
            activeEngraving={rearEngraving}
            engravingSurfaces={surfaces.filter((s) => s.engravingPossible === true)}
            extra={rearExtra}
            fonts={fonts}
            frontExtra={frontExtra}
            frontSurface={frontSurface}
            handleClose={() => setRearEngravingDrawerOpen(false)}
            mediaHost={mediaHost}
            open={rearEngravingDrawerOpen}
            setEngraving={setRearEngraving}
            setExtra={setRearExtra}
            setFrontExtra={setFrontExtra}
            setSurface={setRearSurface}
            surface={rearSurface}
          />

          <StandardInlayDrawer
            activeStandardInlay={rearStandardInlay}
            extra={rearExtra}
            handleClose={() => setRearStandardInlayDrawerOpen(false)}
            inlayCategories={inlayCategories}
            mediaHost={mediaHost}
            open={rearStandardInlayDrawerOpen}
            setExtra={setRearExtra}
            setStandardInlay={setRearStandardInlay}
          />

          <IndividualInlayDrawer
            activeIndividualInlay={rearIndividualInlay}
            extra={rearExtra}
            fonts={fonts}
            handleClose={() => setRearIndividualInlayDrawerOpen(false)}
            inlayArts={inlayArts}
            inlayBackgrounds={inlayBackgrounds}
            mediaHost={mediaHost}
            open={rearIndividualInlayDrawerOpen}
            setIndividualInlay={setRearIndividualInlay}
            setExtra={setRearExtra}
          />
        </Grid>

        <ShippingDialog
          open={shippingDialogOpen}
          handleClose={() => setShippingDialogOpen(false)}
        />
      </>
    );
  } else {
    return <Loader position="absolute" message="Loading" />;
  }
}

export default ConfigureCarsign;
