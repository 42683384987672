import React, { useContext, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { makeStyles } from '@material-ui/styles';

import { CartContext } from '../contexts/CartContext';
import { localeProps } from '../lib/utils';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
  },

  cartButton: {
    borderRadius: 0,
  },

  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1016,
  },

  price: {
    color: theme.colors.orange,
    fontSize: 28,
    paddingLeft: 16,
  },

  productImage: {
    maxWidth: '100%',
  },

  productPaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.colors.darkGrey,
    backgroundColor: theme.colors.lightGrey,
    height: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderRadius: 0,
    fontWeight: 400,

    '& img': {
      maxWidth: '100%',
    },
  },

  voucherInputHeader: {
    color: theme.colors.orange,
    fontWeight: 'bold',
    marginTop: 30,
  },

  voucherInput: {
    marginTop: 10,
    marginBottom: 30,
  },

}));

const Product = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();
  const { description, id, isVoucher, mediaHost, name, price, productImage, deliveryTime } = props;
  const { addProduct, openCartDrawer } = useContext(CartContext);
  
  const [open, setOpen] = useState(false);
  const [voucherPrice, setVoucherPrice] = useState(price);
  const [voucherError, setVoucherError] = useState(false)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleAddCart = () => {
    let productType, productId, sku;
    if (isVoucher) {
      if (Number(voucherPrice) >=  10) {
        productType = 'voucher';
        productId = `${productType}|${id}|${voucherPrice}`;
        sku = `voucher_${voucherPrice}`;
      } else {
        setVoucherError(true);
        return;
      }
    } else {
      productType = 'product';
      productId = `${productType}|${id}`;
      sku = `product_${id}`;
    }

    const newProduct = {
      type: isVoucher ? 'voucher' : 'product',
      id: productId,
      deliveryTime,
      name: isVoucher ? `Gutschein über ${Number(voucherPrice).toLocaleString('de',localeProps)}` : name,
      price: isVoucher ? Number(voucherPrice) : Number(price),
      sku,
      quantity: 0,
      idString: '',
    };
    newProduct.idString = JSON.stringify(newProduct);
    newProduct.quantity = 1;

    addProduct(newProduct);

    openCartDrawer();
    setOpen(false);
  };

  useEffect(() => {
    if (voucherPrice) {
      if (Number(voucherPrice) < 10) {
        setVoucherError(true);
      } else {
        setVoucherError(false);
      }
    }
  }, [voucherPrice])

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Paper
          className={classes.productPaper}
          onClick={() => setOpen(true)}
          elevation={0}
        >
          {name}
        </Paper>
      </Grid>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby={name}
        className="shopElement"
      >
        <IconButton
              aria-label="close"
              className={classes.closeIcon}
              onClick={() => setOpen(false)}
            >
          <HighlightOffIcon fontSize="large"  />
        </IconButton>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
                src={`${mediaHost}${productImage}`} 
                className={classes.productImage}
                alt={name}
            />

            {isVoucher && (
              <>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.voucherInputHeader}
                >
                  {t('Gutschein-Betrag in €')}:
                </Typography>

                <TextField
                  className={classes.voucherInput}
                  fullWidth
                  label={t('voucher_min_10', 'Gutschein-Betrag in € (mind. 10€)')}
                  value={voucherPrice}
                  onChange={(evt) => {
                      setVoucherPrice(evt.target.value);
                  }}
                  type="number"
                  error={voucherError}
                  helperText={voucherError ? t('min_10_eur', 'Der Betrag muss mind. 10€ sein!') : ''}
                />
              </>
            )}

            <div dangerouslySetInnerHTML={{__html: description}} />
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <div className={classes.price}>
            {isVoucher
              ? Number(voucherPrice).toLocaleString('de', localeProps)
              : Number(price).toLocaleString('de', localeProps)
            }
          </div>
          <Button 
              variant="contained"
              color="primary"
              className={classes.cartButton}
              onClick={handleAddCart}
              >
              {t('In den Warenkorb')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Product;
