import React, { useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/styles';

import Drawer from './Drawer';

import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  accordionSummary: {
    backgroundColor: theme.colors.lightGrey,
  },
  
  activeCheckbox: {
    marginBottom: 30,
  },

  artItem: {
    '& img': {
      maxWidth: '100%',
    },
  },

  backgroundItem: {
    '& img': {
      maxWidth: '100%',
    },
  },

  blackCheckbox: {
    color: 'black !important',
  },

  colorsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom:30
  },

  fontList: {
    width: '100%',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  
  preview: {
    width:350,
    height:38,
    maxWidth: '100%',
    position:'relative',
    marginTop:10,
  },
  
  previewArt: {
    position: 'absolute',
    width:350,
    maxWidth: '100%',
    height:'100%',
  },

  previewBackground: {
    position: 'absolute',
    width:350,
    maxWidth: '100%',
    height:'100%',
  },

  previewText: {
    position: 'absolute',
    textAlign:'center',
    width:350,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height:'100%',
  },

  previewWrapper: {
    marginBottom: 30,
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  slectedListItem: {
    backgroundColor: `${theme.colors.orange} !important`,
  },

  subHeader: {
    marginTop: 20,
  },

  text: {
    marginBottom: 40,
    minHeight: 64,
  },

  whiteCheckbox: {
    color: 'white !important',
  },
}));


const IndividualInlayDrawer = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyle();
  const {
    activeIndividualInlay,
    extra,
    fonts,
    inlayArts,
    inlayBackgrounds,
    handleClose,
    mediaHost,
    open,
    setExtra,
    setIndividualInlay,
  } = props;

  const [art, setArt] = useState();
  const [background, setBackground] = useState(inlayBackgrounds[0]);
  const [color, setColor] = useState('black');
  const [expanded, setExpanded] = React.useState(false);
  const [font, setFont] = useState(fonts[0]);
  const [text, setText] = useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let individualInlayArtImage;
  if (art) {
    individualInlayArtImage = color === 'white'
      ? art.inlayArtImageWhite
      : art.inlayArtImageBlack;
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      header='Individuell'
    >
      <TextField
        fullWidth
        label="Text"
        value={text}
        className={classes.text}
        onChange={(evt) => {
          setText(evt.target.value);
          setIndividualInlay({
            art,
            background,
            color,
            font,
            text: evt.target.value,
          });
          if (evt.target.value.length > 0) {
            setExtra('individual-inlay');
          } else {
            setExtra('none');
          }
        }}
      />


      {activeIndividualInlay !== undefined && activeIndividualInlay.text.length > 0 && (
        <div className={classes.previewWrapper}>
          {t('Vorschau')}:

          <div className={classes.preview}>
            <img
              src={`${mediaHost}${background.inlayBackgroundImage}`}
              alt={background.name}
              className={classes.previewBackground}
            />
            {individualInlayArtImage !== undefined && (
              <img
                src={`${mediaHost}${individualInlayArtImage}`}
                alt={art.name}
                className={classes.previewArt}
              />
            )}
            <div
              className={classes.previewText}
              style={{
                fontFamily: font.fontFamily,
                fontWeight: font.fontWeight,
                fontStyle: font.fontStyle,
                color,
              }}
              >
              {text}
            </div>
          </div>
        </div>
      )}



      {activeIndividualInlay !== undefined && activeIndividualInlay.text.length > 0 && (
        <>
          <Tooltip
            title={
              activeIndividualInlay === undefined || activeIndividualInlay.text.length === 0
              ? t('Starten Sie einfach mit einem Text für das Inlay')
              : ''
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={extra === 'individual-inlay'}
                  onChange={() => {
                    if (extra === 'individual-inlay') {
                      setExtra('none');
                    } else {
                      setExtra('individual-inlay');
                    }
                  }}
                  disabled={activeIndividualInlay === undefined  || activeIndividualInlay.text.length === 0}
                  name="checkedB"
                  color="primary"
                />
              }
              label={t('Inlay anwenden')}
              className={classes.activeCheckbox}
            />
          </Tooltip>

          <div className={classes.subHeader}>{t('Schriftfarbe')}</div>
          <div className={classes.colorsWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={color === 'black'}
                  onChange={() => {
                    setColor('black');
                    setIndividualInlay({
                      art,
                      background,
                      color: 'black',
                      font,
                      text,
                    });
                    if (text.length > 0) {
                      setExtra('individual-inlay');
                    }
                  }}
                  name="checkedB"
                  color="primary"
                  classes={{
                    colorPrimary: classes.blackCheckbox, 
                  }}
                />
              }
              label={t('schwarz')}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={color === 'white'}
                  onChange={() => {
                    setColor('white');
                    setIndividualInlay({
                      art,
                      background,
                      color: 'white',
                      font,
                      text,
                    });
                    if (text.length > 0) {
                      setExtra('individual-inlay');
                    }
                  }}
                  name="checkedB"
                  color="secondary"
                  classes={{
                    colorSecondary: classes.whiteCheckbox, 
                  }}
                />
              }
              label={t('weiss')}
            />
          </div>

          <div className={classes.subHeader}>{t('Designelemente')}</div>
          <Accordion
            expanded={expanded === 'backgrounds'}
            onChange={handleChange('backgrounds')}
            style={{ marginTop: 10, }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="backgrounds-content"
              id="backgrounds-header"
              classes={{
                root: classes.accordionSummary,
              }}
            >
              <div className={classes.heading}>{t('Hintergrund')}</div>
              <div className={classes.secondaryHeading}>{background.name}</div>
            </AccordionSummary>
            <AccordionDetails>
              <List component="nav" aria-label="inlay backgrounds">
                {inlayBackgrounds.map((b) => {
                  return (
                    <ListItem
                      key={b.id}
                      button
                      selected={background.id === b.id}
                      onClick={() => {
                        setBackground(b);
                        setIndividualInlay({
                          art,
                          background: b,
                          color,
                          font,
                          text,
                        })
                        if (text.length > 0) { 
                          setExtra('individual-inlay');
                        }
                      }}
                      className={classes.backgroundItem}
                      classes={{
                        selected: classes.slectedListItem,
                      }}
                    >
                      <img
                        src={`${mediaHost}${b.inlayBackgroundImage}`}
                        alt={b.name}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'arts'}
            onChange={handleChange('arts')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="arts-content"
              id="arts-header"
              classes={{
                root: classes.accordionSummary,
              }}
            >
              <div className={classes.heading}>{t('Grafikelement')}</div>
              <div className={classes.secondaryHeading}>{art && art.name}</div>
            </AccordionSummary>
            <AccordionDetails>
              <List component="nav" aria-label="inlay arts">
                <ListItem
                  button
                  selected={art === undefined}
                  onClick={() => {
                    setArt(undefined);
                    setIndividualInlay({
                      art: undefined,
                      background,
                      color,
                      font,
                      text,
                    })
                    if (text.length > 0) { 
                      setExtra('individual-inlay');
                    }
                  }}
                  className={classes.artItem}
                  classes={{
                    selected: classes.slectedListItem,
                  }}
                >
                  <ListItemText primary={t('Ohne Grafikelement')} />
                </ListItem>

                {inlayArts.map((a) => {
                  return (
                    <ListItem
                      key={a.id}
                      button
                      selected={art && art.id === a.id}
                      onClick={() => {
                        setArt(a);
                        setIndividualInlay({
                          art: a,
                          background,
                          color,
                          font,
                          text,
                        })
                        if (text.length > 0) { 
                          setExtra('individual-inlay');
                        }
                      }}
                      className={classes.artItem}
                      classes={{
                        selected: classes.slectedListItem,
                      }}
                    >
                      <img
                        src={`${mediaHost}${a.inlayArtImageBlack}`}
                        alt={a.name}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'fonts'}
            onChange={handleChange('fonts')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="fonts-content"
              id="fonts-header"
              classes={{
                root: classes.accordionSummary,
              }}
            >
              <div className={classes.heading}>{t('Schriftart')}</div>
              <div className={classes.secondaryHeading}>{font.name}</div>
            </AccordionSummary>
            <AccordionDetails>
              <List component="nav" aria-label="fonts" className={classes.fontList}>
                {fonts.map((f) => {
                  return (
                    <ListItem
                      key={f.id}
                      button
                      selected={font.id === f.id}
                      onClick={() => {
                        setFont(f);
                        setIndividualInlay({
                          art,
                          background,
                          color,
                          font: f,
                          text,
                        })
                        if (text.length > 0) { 
                          setExtra('individual-inlay');
                        }
                      }}
                      className={classes.fontItem}
                      classes={{
                        selected: classes.slectedListItem,
                      }}
                    >
                      <ListItemText
                        primary={f.name}
                        disableTypography={true}
                        style={{
                          fontFamily: f.fontFamily,
                          fontWeight: font.fontWeight,
                          fontStyle: font.fontStyle,
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Drawer>
  );
};

export default IndividualInlayDrawer;
