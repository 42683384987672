import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import { makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 0,
  },

  slectedListItem: {
    backgroundColor: `${theme.colors.orange} !important`,
  },
}));


const SurfaceList = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const {
    activeSurface,
    extra,
    mediaHost,
    setExtra,
    setSurface,
    surfaces,
  } = props;
  const classes = useStyles();

  const changeSurface = (s) => {
    if (s.engravingPossible === false && extra === 'engraving') {
      setExtra('none')
    }
    setSurface(s);
  };

  return (
    <List className={classes.root}>
      {surfaces.map((s) => {
        return (
          <ListItem
            button
            key={s.id}
            onClick={() => changeSurface(s)}
            selected={s.id === activeSurface.id}
            classes={{
              selected: classes.slectedListItem,
            }}
          >
            <ListItemAvatar>
              <Avatar
                className={classes.avatar}
                alt={s.name}
                src={`${mediaHost}${s.framePreviewImage}`}
              />
            </ListItemAvatar>
            <div>
              <ListItemText
                primary={s.name}
                secondary={
                  s.engravingPossible ? t('(Lasergravur möglich)') : ''
                }
              />
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SurfaceList;
