import React, { useContext, useEffect, useState } from 'react';

import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { PayPalButtons } from "@paypal/react-paypal-js";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

import AddressForm from './AddressForm';
import CartForm from './CartForm';

import FinishPaymentForm from './FinishPaymentForm';
import Loader from './Loader';
import PaymentForm from './PaymentForm';
import Summary from './Summary';

import { CartContext } from '../contexts/CartContext';

import { host, getCookie } from '../lib/utils';

import { useTranslation } from 'react-i18next';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      paddingTop: 40,
    },
  },

  appBar: {
    position: 'relative',
  },

  header: {
    marginTop:40,
  },

  nextButton: {
    marginLeft: 15,
  },

  title: {
    color: '#fff',
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  wrapper: {
    padding: '0 40px',
    width: 1200,
    maxWidth: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

const initialOrderData = {
  shippingCompany: '',
  shippingSalutation: 'F',
  shippingTitle: '',
  shippingFirstName: '',
  shippingLastName: '',
  shippingLine1: '',
  shippingLine1a: '',
  shippingLine2: '',
  shippingZipCode: '',
  shippingCity: '',
  shippingCountry: 'DE',

  phone: '',
  email: '',

  billingCompany: '',
  billingSalutation: 'F',
  billingTitle: '',
  billingFirstName: '',
  billingLastName: '',
  billingLine1: '',
  billingLine1a: '',
  billingLine2: '',
  billingZipCode: '',
  billingCity: '',
  billingCountry: 'DE',

  identicalShippingAddress: true,

  paymentType: 'VK',
  paymentID: '',
  payerID: '',

  accountOwner: '',
  accountBank: '',
  accountIban: '',
  accountBic: '',

  howDidYouGetHere: undefined,
  howDidYouGetHereMessage: '',
  message: '',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Checkout(props) {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();
  const { open, handleClose } = props;
  const {
    countries,
    total,
    cartItems,
    clearCart,
    itemCount,
    voucherAmount,
    validVoucherCode,
    deliveryTimeDisplayName,
  } = useContext(CartContext);

  // stepper
  const [activeStep, setActiveStep] = useState(0);
  const [nextBtnText, setNextBtnText] = useState('Weiter');

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...")

  const [acceptAGB, setAcceptAGB] = useState(false);
  const [acceptAGBError, setAcceptAGBError] = useState(false);
  const [accountErrors, setAccountErrors] = useState({});
  const [addressErrors, setAddressErrors] = useState({});
  const [orderData, setOrderData] = useState(initialOrderData);

  const [payPalCanceledMessage, setPayPalCanceledMessage] = useState('');
  const [payPalErrorMessage, setPayPalErrorMessage] = useState('');

  const [openInfoMessage, setOpenInfoMessage] = useState(false);
  const [infoMessageLevel, setInfoMessageLevel] = useState('success');
  const [infoMessageContent, setInfoMessageContent] = useState('');

  const [shipping, setShipping] = useState(0);
  const [shipToCountry, setShipToCountry] = useState(undefined);

  const handleCloseCheckout = () => {
    handleClose();
    setActiveStep(0);
  }

  const handleAddressChange = (name, value) => {
    setOrderData({
      ...orderData,
      [name]: value,
    });
  };

  const handlePaymentChange = (evt) => {
    setOrderData({
      ...orderData,
      paymentType: evt.target.value,
    });
  };

  const emailValidation = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateAlphabet = (value) => {
    const re = /^[a-zA-Z_äÄöÖüÜß\.:_,;\- ]*$/;
    return re.test(String(value));
  };

  const getSteps = () => {
    return [
      t('Warenkorb'),
      t('Adressdaten'),
      t('Zahlungsart'),
      t('Bestellung abschliessen'),
      t('Zusammenfassung'),
    ];
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CartForm
            setInfoMessageLevel={setInfoMessageLevel}
            setInfoMessageContent={setInfoMessageContent}
            setOpenInfoMessage={setOpenInfoMessage}
          />
        );
        break;

      case 1:
        return (
          <AddressForm
            {...orderData}
            {...addressErrors}
            handleChange={handleAddressChange}
            handleBillingChange={() => {
              setOrderData({
                ...orderData,
                identicalShippingAddress: !orderData.identicalShippingAddress,
              });
            }}
          />
        );  
        break;

      case 2:
        const shipToCountryCode = orderData.identicalShippingAddress
          ? orderData.billingCountry
          : orderData.shippingCountry;
        if ((orderData.paymentType === 'NN' || orderData.paymentType === 'LS') && shipToCountryCode !== 'DE') {
          setOrderData({
            ...orderData,
            paymentType: 'VK',
          });
        }
        return (
          <>
            <PaymentForm
              country={shipToCountryCode}
              {...orderData}
              {...accountErrors}
              paymentType={orderData.paymentType}
              handleChange={handlePaymentChange}
              handleAccountChange={handleAddressChange}
            />
          </>
        );
        break;

      case 3:
        return (
          <FinishPaymentForm
            {...orderData}
            handleChange={handleAddressChange}
            acceptAGB={acceptAGB}
            setAcceptAGB={setAcceptAGB}
            acceptAGBError={acceptAGBError}
            shipping={shipping}
            shipToCountry={shipToCountry}
          />
        );
        break;

      case 4:
        return <Summary {...orderData} />;
        break;

      default:
        return 'Unknown step';
        break;
    }
  };

  useEffect(() => {
    if (acceptAGB===true) {
      setAcceptAGBError(false);
    }
  }, [acceptAGB]);

  useEffect(() => {
    if (activeStep === 3) {
      setNextBtnText(t('Zahlungspflichtig bestellen'));
    } else {
      setNextBtnText(t('Weiter'));
    }
  }, [activeStep]);

  const handleNext = () => {
    if (activeStep === 1) {
      // ADDRESS FORM

      // validata billing fields
      let fieldsValid = true;
      const newAddressErrors = {};
      if (orderData.billingFirstName.trim() === '') {
        newAddressErrors.errorBillingFirstName = true;
        fieldsValid = false;
      }
      if (orderData.billingLastName.trim() === '') {
        newAddressErrors.errorBillingLastName = true;
        fieldsValid = false;
      }
      if (orderData.billingLine1.trim() === '' || !validateAlphabet(orderData.billingLine1)) {
        newAddressErrors.errorBillingLine1 = true;
        fieldsValid = false;
      }
      if (orderData.billingLine1a.trim() === '') {
        newAddressErrors.errorBillingLine1a = true;
        fieldsValid = false;
      }
      if (orderData.billingZipCode.trim() === '') {
        newAddressErrors.errorBillingZipCode = true;
        fieldsValid = false;
      }
      if (orderData.billingCity.trim() === '') {
        newAddressErrors.errorBillingCity = true;
        fieldsValid = false;
      }

      if (orderData.email.trim() === '' || !emailValidation(orderData.email)) {
        newAddressErrors.errorEmail = true;
        fieldsValid = false;
      }

      // validate shipping fieds if required
      if (!orderData.identicalShippingAddress) {
        if (orderData.shippingFirstName.trim() === '') {
          newAddressErrors.errorShippingFirstName = true;
          fieldsValid = false;
        }
        if (orderData.shippingLastName.trim() === '') {
          newAddressErrors.errorShippingLastName = true;
          fieldsValid = false;
        }
        if (orderData.shippingLine1.trim() === '' || !validateAlphabet(orderData.shippingLine1)) {
          newAddressErrors.errorShippingLine1 = true;
          fieldsValid = false;
        }
        if (orderData.shippingLine1a.trim() === '') {
          newAddressErrors.errorShippingLine1a = true;
          fieldsValid = false;
        }
        if (orderData.shippingZipCode.trim() === '') {
          newAddressErrors.errorShippingZipCode = true;
          fieldsValid = false;
        }
        if (orderData.shippingCity.trim() === '') {
          newAddressErrors.errorShippingCity = true;
          fieldsValid = false;
        }
      }

      setAddressErrors(newAddressErrors);

      // calculate shipping based on country
      const billingCountryObj = countries.find((c) => c.countryCode === orderData.billingCountry);
      const shippingCountryObj = countries.find((c) => c.countryCode === orderData.shippingCountry);
      const country = orderData.identicalShippingAddress
        ? billingCountryObj
        : shippingCountryObj;

      setShipToCountry(country);

      const shippingObj = orderData.identicalShippingAddress
        ? billingCountryObj.shipping
        : shippingCountryObj.shipping;

      let shippingCost = 0;
      cartItems.forEach((item) => {
        if (item.type === 'product' && shippingObj.priceWithoutCarsign > shippingCost) {
          shippingCost = shippingObj.priceWithoutCarsign;
        }
        if (item.type === 'carsign') {
          shippingCost = shippingObj.priceWithCarsign;
        }
      });
      setShipping(shippingCost);

      // check if total cost is bigger than 0 
      // if not no payment necessary
      if (voucherAmount && Number(total) + Number(shippingCost) - Number(voucherAmount) <= 0) {
        setOrderData({
          ...orderData,
          paymentType: 'UN',
        });
      } else {
        setOrderData({
          ...orderData,
          paymentType: 'VK',
        });
      }


      if (fieldsValid) {
        setActiveStep(activeStep + 1);
      }

    } else if (activeStep === 2) {
      // PAYMENT FORM

      // validata account fields
      let fieldsValid = true;
      const newAccountErrors = {};
      // only for 'Lastschrift'
      if (orderData.paymentType === 'LS') {
        if (orderData.accountOwner.trim() === '') {
          newAccountErrors.errorAccountOwner = true;
          fieldsValid = false;
        }
        if (orderData.accountBank.trim() === '') {
          newAccountErrors.errorAccountBank = true;
          fieldsValid = false;
        }
        if (orderData.accountIban.trim() === '') {
          newAccountErrors.errorAccountIban = true;
          fieldsValid = false;
        }
        if (orderData.accountBic.trim() === '') {
          newAccountErrors.errorAccountBic = true;
          fieldsValid = false;
        }

        setAccountErrors(newAccountErrors);
      }

      if (fieldsValid) {
        setActiveStep(activeStep + 1);
      }

    } else if (activeStep === 3) {
      // FINISH PAYMENT
      if (!acceptAGB) {
        setAcceptAGBError(true);
      } else {
      setLoadingMessage("Bestellung wird bearbeitet...");
      setLoading(true);
      fetch(host + '/order/create/', {
        method: 'POST',
        headers: {
        },
        body: JSON.stringify({
            total,
            cartItems,
            orderData,
            validVoucherCode,
            voucherAmount,
            deliveryTimeDisplayName,
          }),
        })
          .then((response) => 
            response.json().then(data => ({
              data: data,
              status: response.status
          }))
          .then(res => {
            setLoading(false);
            if (res && res.data && res.data.details && res.data.details === 'success') {
              setActiveStep(activeStep+1)
              ReactGA.event({
                category: 'Webshop',
                action: 'Kauf',
                label: 'Kauf abgeschlossen',
                value: Number(total)
              });

              var products = [];

              cartItems.forEach((item) => {
                let cat = 'CarSign Kennzeichenhalter';
                if (item.type === 'product') {
                  cat = 'Zubehör';
                }
                if (item.type === 'voucher') {
                  cat = 'Gutschein';
                }

                products.push({
                    id: res.data.orderId,
                    name: item.name,
                    price: item.price,
                    category: cat,
                    quantity: item.quantity,
                    sku: item.sku,
                });
              });
      
              ReactGA._gtag('ecommerce', null);
              ReactGA._gtag('ecommerce', 'purchase', {
                'products': products,
              });

              ReactPixel.track('Purchase', {
                value: Number(total),
                currency: 'EUR',
                num_items: itemCount,
              });
              
              clearCart();
            } else {
              setInfoMessageLevel('error');
              setInfoMessageContent(
                <div>
                  <h3>{t('Fehler')}</h3>
                  <p>
                    {t('Leider ist ein Problem aufgetreten und es war nicht möglich Deine Bestellung zu speichern')}.
                  </p>
                  <p>{t('Bitte versuchen Sie es später erneut')}!</p>
                </div>
              );
              setOpenInfoMessage(true);
            }
          }))
          .catch((err) => {
            /* Go to Error page */
            setLoading(false);
            setInfoMessageLevel('error');
            setInfoMessageContent(
              <div>
                <h3>{t('Fehler')}</h3>
                <p>
                  {t('Leider ist ein Problem aufgetreten und es war nicht möglich Deine Bestellung zu speichern')}.
                </p>
                <p>{t('Bitte versuchen Sie es später erneut')}!</p>
              </div>
            );
            setOpenInfoMessage(true);
            return false;
          });
      }
    } else {
      setActiveStep(activeStep + 1)
    }
  };

  const payPalCreateOrder = (data, actions) => {
    let usePayPalShippingData;
    if (activeStep === 0) {
      usePayPalShippingData = true;
    } else {
      usePayPalShippingData = false;
    }

    return fetch(host + '/paypal/create/', {
      method: 'post',
      headers: {
      },
      body: JSON.stringify({
        total,
        cartItems,
        orderData,
        validVoucherCode,
        voucherAmount,
        usePayPalShippingData,
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (createdOrderData) {
        return createdOrderData.id;
      });
  };

  const payPalOnShippingChange = (data, actions) => {
    // Reject not allowed addresses
    if (countries.find(
      (c) => c.countryCode === data.shipping_address.country_code
    ) === undefined) {
      return actions.reject();
    }

    return fetch(host + '/paypal/update/' + data.orderID + '/', {
      method: 'post',
      headers: {
      },
      body: JSON.stringify({
        total,
        cartItems,
        orderData,
        data,
        validVoucherCode,
        voucherAmount,
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (updatedOrderData) {
        return updatedOrderData.id;
      });
  };

  const payPalOnApprove = (data, actions) => {
    setLoadingMessage("Bestellung wird bearbeitet...");
    setLoading(true);

    let usePayPalShippingData;
    if (activeStep === 0) {
      usePayPalShippingData = true;
    } else {
      usePayPalShippingData = false;
    }

    return fetch(host + '/paypal/capture/' + data.orderID + '/', {
      method: 'post',
      headers: {
      },
      body: JSON.stringify({
        total,
        cartItems,
        data,
        orderData,
        validVoucherCode,
        voucherAmount,
        deliveryTimeDisplayName,
        usePayPalShippingData,
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // Three cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Other non-recoverable errors -> Show a failure message
        //   (3) Successful transaction -> Show a success / thank you message

        const capturedOrderData = data.data;
        // Your server defines the structure of 'capturedOrderData', which may differ
        const errorDetail =
          Array.isArray(capturedOrderData.details) &&
          capturedOrderData.details[0];

        if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
          // Recoverable state, see: "Handle Funding Failures"
          // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
          return actions.restart();
        }

        if (errorDetail) {
          let msg = 'Sorry, your transaction could not be processed.';
          if (errorDetail.description) msg += '\n\n' + errorDetail.description;
          if (capturedOrderData.debug_id)
            msg += ' (' + capturedOrderData.debug_id + ')';
          // Show a failure message
          return alert(msg);
        }

        // Show a success message to the buyer
        setLoading(false);
        ReactGA.event({
          category: 'Webshop',
          action: 'Kauf',
          label: 'Kauf abgeschlossen',
          value: Number(total)
        });

        var products = [];

        cartItems.forEach((item) => {
          let cat = 'CarSign Kennzeichenhalter';
          if (item.type === 'product') {
            cat = 'Zubehör';
          }
          if (item.type === 'voucher') {
            cat = 'Gutschein';
          }
          
          products.push({
              id: data.orderId,
              name: item.name,
              price: item.price,
              category: cat,
              quantity: item.quantity,
              sku: item.sku,
          });
        });

        ReactGA._gtag('ecommerce', null);
        ReactGA._gtag('ecommerce', 'purchase', {
          'products': products,
        });

        ReactPixel.track('Purchase', {
          value: Number(total),
          currency: 'EUR',
          num_items: itemCount,
        });

        clearCart();
        setOrderData({
          ...orderData,
          email: capturedOrderData.payer.email_address,
        });
        setActiveStep(4);
        setInfoMessageLevel('success');
        setInfoMessageContent(
          <div>
            <h3>{t('Zahlung erfolgreich')}</h3>
            <p>{t('Die Bezahlung über PayPal war erfolgreich!')}</p>
            <p>
              <i>{t('Vielen Dank!')}</i>
            </p>
          </div>
        );
        setOpenInfoMessage(true);
      });
  };

  const payPalOnCancel = () => {
    setInfoMessageLevel('error');
    setInfoMessageContent(
      <div>
        <h3>{t('Zahlung abgebrochen')}</h3>
        <p>{t('Die Bezahlung über PayPal wurde abgebrochen!')}</p>
      </div>
    );
    setOpenInfoMessage(true);

    return setPayPalCanceledMessage('Du hast die Bezahlung abgebrochen!');
  };

  const payPalOnError = (err) => {
    setInfoMessageLevel('error');
    setInfoMessageContent(
      <div>
        <h3>{t('Fehler')}</h3>
        <p>{t('Leider ist bei der Bezahlung über PayPal ein Fehler aufgetreten')}</p>
        <p>{t('Bitte versuchen Sie es später erneut')}!</p>
      </div>
    );
    setOpenInfoMessage(true);

    return setPayPalErrorMessage(
      t('error_occurred', 'Es ist ein Fehler während der Bezahlung aufgetreten.')
    );
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCloseCheckout}
      TransitionComponent={Transition}
      className="shopElement"
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.wrapper}>
          <IconButton edge="start" color="inherit" onClick={handleCloseCheckout} aria-label="close">
              <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h1" className={classes.title}>
            Bestellung
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.wrapper}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {getSteps().map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent className={classes.stepContent}>
                    {loading && (
                      <Loader position="absolute" message={loadingMessage} />
                    )}
                    {getStepContent(index)}

                    <div className={classes.actionsContainer}>
                      {activeStep > 0 && activeStep < 4 && (
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={() => setActiveStep(activeStep - 1)}
                            className={classes.button}
                          >
                            {t('Zurück')}
                          </Button>
                        </div>
                      )}
                      {activeStep < 4 && total > 0 && (
                        <>
                          {activeStep === 0 && total > voucherAmount && (
                            <PayPalButtons
                              style={{
                                layout: 'horizontal',
                                label: 'checkout',
                                height: 38,
                              }}
                              createOrder={payPalCreateOrder}
                              onApprove={payPalOnApprove}
                              onShippingChange={payPalOnShippingChange}
                              onCancel={payPalOnCancel}
                              onError={payPalOnError}
                              forceReRender={validVoucherCode}
                            />
                          )}

                          {(activeStep !== 3 || orderData.paymentType !== 'PP') && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              className={classes.nextButton}
                            >
                              {nextBtnText}
                            </Button>
                          )}

                          {activeStep === 3 && orderData.paymentType === 'PP' && (
                            <PayPalButtons
                              style={{
                                layout: 'horizontal',
                                label: 'pay',
                                height: 38,
                              }}
                              createOrder={payPalCreateOrder}
                              onApprove={payPalOnApprove}
                              onCancel={payPalOnCancel}
                              onError={payPalOnError}
                              forceReRender={acceptAGB}
                              onClick={() => {
                                if (!acceptAGB) {
                                  setAcceptAGBError(true);
                                  return false;
                                } else {
                                  return true;
                                }
                              }} 
                            />
                          )}
                        </>
                      )}
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={openInfoMessage}
        autoHideDuration={6000}
        onClose={() => setOpenInfoMessage(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={() => setOpenInfoMessage(false)} severity={infoMessageLevel}>
          {infoMessageContent}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default Checkout;
