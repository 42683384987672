import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import CheckIcon from '@material-ui/icons/Check';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1016,
  },

  drawer: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      width: '100%',
    },
    width: '33.3333%',
    minWidth: 320,
    top: 0,
    height: '100%',
    maxHeight: '100vh',
    right: 0,
    backgroundColor: '#d8d8d8',
    padding:20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    zIndex: 1200,
  },

  drawerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#d8d8d8',
  },

  header: {
    color: theme.colors.orange,
    height: 35,
    lineHeight: '35px',
    textTransform: 'uppercase',
    marginBottom: 30,
    paddingRight: 60,
  },
}));


const Drawer = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const { open, onClose, header, drawerOverlay, children } = props;
  const classes = useStyles();

  const handleClickAway = () => {
    if ( open === true) {
      onClose();
    }
  }

  return (
    
    <ClickAwayListener
      onClickAway={handleClickAway}
      mouseEvent={
        open === true
        ? 'onMouseUp'
        : false
      }
    >
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
          <Paper elevation={3} className={classes.drawer}>
            <Button
              variant="contained"
              className={classes.closeIcon}
              onClick={onClose}
              color="primary"
              startIcon={<CheckIcon />}
            >
              {t('OK')}
            </Button>

            <div className={classes.header}>
              {header}
            </div>
            {children}

            {drawerOverlay && (
              <div className={classes.drawerOverlay}>
                {drawerOverlay}
              </div>
            )}
          </Paper>
      </Slide>
    </ClickAwayListener>
  );
};

export default Drawer;
