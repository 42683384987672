import React, { useContext, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/styles';

import { CartContext } from '../contexts/CartContext';
import { localeProps } from '../lib/utils';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  
}));

const Shipping = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();
  const { open, handleClose } = props;
  const { shippings } = useContext(CartContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby={t('Versandkosten-Übersicht')}
      className="shopElement"
    >
      <DialogTitle>{t('Versandkosten-Übersicht')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('Region')}</TableCell>
                  <TableCell align="right">{t('mit CarSign-Kennzeichenhalter')}</TableCell>
                  <TableCell align="right">{t('ohne CarSign-Kennzeichenhalter')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shippings.map((shipping) => (
                  <TableRow key={shipping.name}>
                    <TableCell component="th" scope="row">
                      {shipping.name}
                    </TableCell>
                    <TableCell align="right">{Number(shipping.priceWithCarsign).toLocaleString('de', localeProps)}</TableCell>
                    <TableCell align="right">{Number(shipping.priceWithoutCarsign).toLocaleString('de', localeProps)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default Shipping;
