import React from 'react';

import { makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  engraving: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '10%',
    fontSize: '13px',
    color: 'rgba(255, 255, 255, 0.6)',

    '@media screen and (max-width: 1200px)': {
      fontSize: '12px',
    },
    '@media screen and (max-width: 1100px)': {
      fontSize: '11px',
    },
    '@media screen and (max-width: 959px)': {
      fontSize: '13px',
    },
    '@media screen and (max-width: 800px)': {
      fontSize: '12px',
    },
    '@media screen and (max-width: 700px)': {
      fontSize: '11px',
    },
    '@media screen and (max-width: 620px)': {
      fontSize: '10px',
    },
    '@media screen and (max-width: 560px)': {
      fontSize: '9px',
    },
    '@media screen and (max-width: 500px)': {
      fontSize: '8px',
    },
    '@media screen and (max-width: 450px)': {
      fontSize: '7px',
    },
    '@media screen and (max-width: 400px)': {
      fontSize: '6px',
    },
  },

  frameImage: {
    flex: 1,
    lineHeight: 'normal',
    position: 'relative',
    maxWidth: 850,
  },

  frameTheImage: {
    display: 'block',
    maxWidth: "100%",
  },

  inlay: {
    position: 'absolute',
    left: '30%',
    width: '41%',
    bottom: '-2%',
    height: 'auto',
    
    '& img': {
      maxWidth: '100%',
    },
    
  },
  
  inlayText: {
    fontSize: '18px',
    position: 'absolute',
    left: '30%',
    width: '41%',
    bottom: '4%',
    height: 'auto',
    textAlign: 'center',
    color: individualInlay => individualInlay.color,

    '@media screen and (max-width: 1200px)': {
      bottom: '3%',
      fontSize: '17px',
    },
    '@media screen and (max-width: 1100px)': {
      fontSize: '16px',
    },
    '@media screen and (max-width: 959px)': {
      fontSize: '18px',
      bottom: '4%',
    },
    '@media screen and (max-width: 800px)': {
      bottom: '3%',
      fontSize: '17px',
    },
    '@media screen and (max-width: 700px)': {
      fontSize: '16px',
    },
    '@media screen and (max-width: 620px)': {
      fontSize: '15px',
    },
    '@media screen and (max-width: 560px)': {
      bottom: '2%',
      fontSize: '14px',
    },
    '@media screen and (max-width: 500px)': {
      fontSize: '12px',
    },
    '@media screen and (max-width: 450px)': {
      fontSize: '11px',
    },
    '@media screen and (max-width: 400px)': {
      fontSize: '10px',
    },
  },
}));

const Frame = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const {
    engraving,
    extra,
    standardInlay,
    individualInlay,
    mediaHost,
    surface
  } = props;
  
  const classes = useStyle(individualInlay);

  const frameImage = extra === 'standard-inlay' || extra === 'individual-inlay'
    ? surface.frameWithInlayImage
    : surface.frameImage;

  let individualInlayArtImage;
  if (individualInlay && individualInlay.art) {
    individualInlayArtImage = individualInlay.color === 'white'
      ? individualInlay.art.inlayArtImageWhite
      : individualInlay.art.inlayArtImageBlack;
  }

  const withoutInlayText = t('ohne Inlay');

  return (
    <div className={classes.frameImage}>
      <img
        className={classes.frameTheImage}
        src={`${mediaHost}${frameImage}`}
        alt={`${surface.name} ${withoutInlayText}`}
      />
      {extra === 'engraving' && (
        <div
          className={classes.engraving}
          style={{
            fontFamily: engraving.font.fontFamily,
            fontWeight: engraving.font.fontWeight,
            fontStyle: engraving.font.fontStyle,
            color: `#${surface.frameEngravingTextColor}`,
          }}
          >
          {engraving.text}
        </div>
      )}

      {extra === 'standard-inlay' && (
        <div className={classes.inlay}>
          <img
            src={`${mediaHost}${standardInlay.inlayImage}`} alt={standardInlay.nam} />
        </div>
      )}

      {extra === 'individual-inlay' && (
        <>
          <div className={classes.inlay}>
            <img
              src={`${mediaHost}${individualInlay.background.inlayBackgroundImage}`}
              alt={individualInlay.background.name}
            />
          </div>
          {individualInlayArtImage !== undefined && (
            <div className={classes.inlay}>
              <img
                src={`${mediaHost}${individualInlayArtImage}`}
                alt={individualInlay.art.name}
              />
            </div>
          )}
          <div
            className={classes.inlayText}
            style={{
              fontFamily: individualInlay.font.fontFamily,
              fontWeight: individualInlay.font.fontWeight,
              fontStyle: individualInlay.font.fontStyle,
            }}
          >
            {individualInlay.text}
          </div>
        </>
      )}
    </div>
  );
};

export default Frame;
