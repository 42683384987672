import React from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/styles';

import Drawer from './Drawer';
import SurfaceList from './SurfaceList';

import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  actions: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
    
  button: {
  },

  fontList: {
    width: '100%',
  },

  fontsWrapper: {
    marginTop: 60,
  },

  header: {
    color: theme.colors.orange,
    marginBottom: 20,
    textTransform: 'uppercase',
  },

  overlayWrapper: {
    padding: '60px 20px 20px 20px',
  },

  previewTextWraper: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginTop:10,
    width: '100%',
    height: 61,
  },
  
  previewText: {
    lineHeight: '61px',
    height: 61,
    textAlign: 'center',
    fontSize: '36px',
    paddingTop: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  previewWrapper: {
    marginBottom: 30,
    marginTop: 30,
  },

  slectedListItem: {
    backgroundColor: `${theme.colors.orange} !important`,
  },

  surfacePreview: {
    cursor: 'pointer',

    '&.active': {
      backgroundColor: theme.colors.orange,
    },
  },

  text: {
    minHeight: 64,
  },
}));

const EngravingDrawer = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyle();
  const {
    mediaHost,
    extra,
    fonts,
    frontExtra,
    frontSurface,
    open,
    handleClose,
    setEngraving,
    setExtra,
    setFrontExtra,
    activeEngraving,
    surface,
    setSurface,
    engravingSurfaces,
  } = props;

  let drawerOverlay;
  if (surface.engravingPossible === false) {
    drawerOverlay = (
      <div className={classes.overlayWrapper}>
        <p>
          {t('Gravuren sind auf der gewählten Oberfläche nicht möglich')}.
        </p>

        <p>
          {t('Wenn Sie eine Gravur wünschen können Sie aus folgenden Oberflächen wählen')}:
        </p>

        <SurfaceList
          activeSurface={surface}
          extra={extra}
          mediaHost={mediaHost}
          setExtra={setExtra}
          setSurface={setSurface}
          surfaces={engravingSurfaces}
        />
      </div>
    );
  }

  let tooltipFront = '';
  let frontDisabled = false;
  if (extra !== 'engraving') {
    tooltipFront = t('Front-Gravur ist nur zusammen mit Heck-Gravur möglich');
    frontDisabled = true;
  }
  if (activeEngraving.text === '') {
    tooltipFront = t('Geben Sie einen Text ein');
    frontDisabled = true;
  }
  if (frontSurface.engravingPossible === false) {
    tooltipFront = t('Wählen Sie für die Front eine andere Oberfläche');
    frontDisabled = true;
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      header={t('Lasergravur')}
      drawerOverlay={drawerOverlay}
    >
      <TextField
        fullWidth
        className={classes.text}
        label={t('Text')}
        value={activeEngraving.text}
        onChange={(evt) => {
          setEngraving({
            text: evt.target.value,
            font: activeEngraving.font,
          });
          if (evt.target.value.length > 0) {
            setExtra('engraving');
          } else {
            setExtra('none');
          }
        }}
      />

      { activeEngraving.text !== '' && (
        <div className={classes.previewWrapper}>
          {t('Vorschau')}:
          <div
            className={classes.previewTextWraper}
            style={{
              backgroundImage: `url(${mediaHost}${surface.frameEngravingPreviewImage})`,
            }}
          >
            <div
              className={classes.previewText}
              style={{
                fontFamily: activeEngraving.font.fontFamily,
                fontWeight: activeEngraving.font.fontWeight,
                fontStyle: activeEngraving.font.fontStyle,
                color: `#${surface.frameEngravingTextColor}`,
              }}
            >
              {activeEngraving.text}
            </div>
          </div>
        </div>
      )}

      { activeEngraving.text !== '' && (
        <Tooltip
          title={
            activeEngraving.text === ''
            ? t('Geben Sie einen Text ein')
            : ''
          }
        >
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={extra === 'engraving'}
                  onChange={() => {
                    if (extra === 'engraving') {
                      setExtra('none');
                    } else {
                      setExtra('engraving');
                    }
                  }}
                  disabled={activeEngraving.text === ''}
                  name="checkedA"
                  color="primary"
                />
              }
              label={t('Gravur anwenden')}
            />
          </div>
        </Tooltip>
      )}

      { activeEngraving.text !== '' && (
        <Tooltip title={tooltipFront}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={frontExtra === 'engraving'}
                  onChange={() => {
                    if (frontExtra === 'engraving') {
                      setFrontExtra('none');
                    } else {
                      setFrontExtra('engraving');
                    }
                  }}
                  disabled={frontDisabled}
                  name="checkedB"
                  color="primary"
                />
              }
              label={t('Gravur auch für Front')}
            />
          </div>
        </Tooltip>
      )}

      { activeEngraving.text !== '' && (
        <div className={classes.fontsWrapper}>
          <div className={classes.header}>
            {t('Schriftart wählen')}
          </div>
          <List component="nav" aria-label="font" className={classes.fontList}>
            {fonts.map((font) => {
              return (
                <ListItem
                  key={font.id}
                  button
                  selected={activeEngraving.font.id === font.id}
                  onClick={(evt) => {
                    setEngraving({
                      text: activeEngraving.text,
                      font: font,
                    })
                  }}
                  classes={{
                    selected: classes.slectedListItem,
                  }}
                >
                  <ListItemText
                    primary={font.name}
                    disableTypography={true}
                    style={{
                      fontFamily: font.fontFamily,
                      fontWeight: font.fontWeight,
                      fontStyle: font.fontStyle,
                    }}
                  />
                </ListItem>
              )
            })}
          </List>
        </div>
      )}

      {/* <div className={classes.actions}>
        <Button
          variant="contained"
          color="default"
          size="small"
          className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={() => {
            setEngraving({
              text: '',
              font: fonts[0],
            });
            setExtra('none');
          }}
        >
          zurücksetzen
        </Button>

        <Button
          variant="contained"
          color="default"
          size="small"
          className={classes.button}
          startIcon={<CloseIcon />}
          onClick={handleClose}
        >
          schließen
        </Button>
      </div> */}
    </Drawer>
  );
};

export default EngravingDrawer;
