import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import Cart from './Components/Cart';
import { CartContext } from './contexts/CartContext';
import ConfigureCarsign from './Components/ConfigureCarsign';

import { BASICS_QUERY } from './lib/queries';


ReactGA.initialize('G-STCEWFX260', {});

const advancedMatching = {};
const options = {
  autoConfig: true,
  // debug: true,
};
// ReactPixel.init('869677230478278', advancedMatching, options);

const useStyles = makeStyles((theme) => ({
  '@global': {
    'body': {
      backgroundColor: 'white',
      color: theme.colors.darkGrey,
      margin: 0,
      fontFamily: 'Montserrat',
      fontWeight: 300,
      webkitFontSmoothing: 'antialiased',
      mozOsxFontSmoothing: 'grayscale',
    },
    
    'code': {
      fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
    },

    '.inlineLink': {
      color: theme.colors.orange,
      cursor: 'pointer',
    },

    '.light-grey-text': {
      color: theme.colors.lightGrey,
    },

    '.orange-text': {
      color: theme.colors.orange,
    },
  },

  cartBadge: {
    backgroundColor: theme.colors.darkGrey,
    top: -7,
    left: -7,
  },

  cartButton: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 0,
  },

  chatButton: {
    display: 'block',
    textDecoration: 'none',
    height: 40,
    backgroundColor: '#666666',
    color: 'white',
    borderRadius: 0,
    width: 60,
    marginLeft: -10,
    marginBottom: 20,
    textAlign: 'center',
    lineHeight: '40px',
    textTransform: 'uppercase',
    transform: 'rotate(-90deg)',
  },
  
  configAppOuter: {
    position: 'relative',
    minHeight: 400,
  },

  configApp: {
    padding: '80px 0',
    maxWidth: 1200,
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    },
  },
  
  header: {
    textAlign: 'center',
    marginBottom: 80,
  },

  headline: {
    textTransform: 'uppercase',
    fontSize: 28,
    fontWeight: 400,
  },

  sideBar: {
    position: 'absolute',
    right: 0,
    bottom: 80,
    width: 40,
  },
}));


const App = () => {
  const classes = useStyles();

  const {
    cartDrawerIsOpen,
    cartItems,
    closeCartDrawer,
    itemCount,
    openCartDrawer,
    setCarsignBankAccount,
    setCashOnDelivery,
    setCountries,
    setDefaultDeliveryTime,
    setMediaHost,
    setShippings,
  } = useContext(CartContext);

  const { loading, error, data } = useQuery(BASICS_QUERY);

  const toggleCartDrawer = () => {
    if (cartDrawerIsOpen) {
      closeCartDrawer();
    } else {
      openCartDrawer();
    }
  };

  useEffect(() => {
    if (loading === false && data) {
      setCarsignBankAccount(data.carsignBankAccount);
      setCashOnDelivery(data.cashOnDelivery);
      setCountries(data.countries);
      setDefaultDeliveryTime({
        displayName: data.defaultDeliveryTime.displayName,
        lengthInDays: data.defaultDeliveryTime.lengthInDays,
      });
      setMediaHost(data.mediaHost);
      setShippings(data.shippings);
    }
  }, [loading, data]);

  return (
    <div className={classes.configAppOuter}>
      <div className={classes.configApp}>
        <main>
          <ConfigureCarsign />
          <Cart />
        </main>
      </div>

      <div className={classes.sideBar}>
          <a href="mailto:info@carsign.de" className={classes.chatButton}>
            Chat
          </a>

          <IconButton
            className={classes.cartButton}
            color="inherit"
            onClick={() => {
              toggleCartDrawer();
            }}
          >
            {itemCount > 0 && (
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                badgeContent={itemCount}
                color="secondary"
                classes={{ badge: classes.cartBadge }}
              >
                <ShoppingCart />
              </Badge>
            )}
            {cartItems.length < 1 && <ShoppingCart />}
          </IconButton>
        </div>
    </div>
  );
}

export default App;
