import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';

import CartContextProvider from './contexts/CartContext';
import theme from './lib/theme';
import { host } from './lib/utils';
import App from './App';
import SelectCar from './Components/SelectCar';

import './i18n';

import './../assets/css/template.css';
import './../assets/css/template_overrides.css';
import './../assets/css/fonts.css';


import {
  transitions,
  positions,
  Provider as AlertProvider,
  types,
} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

// optional configuration for react-alert
const alertOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
  type: types.SUCCESS,
}

const client = new ApolloClient({
  cache: new InMemoryCache({}),
  uri: host + '/graphql',
});

const shopElement = document.getElementById('shop_configurator');

if (shopElement){
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Suspense fallback="loading">
            <AlertProvider template={AlertTemplate} {...alertOptions}>
              <CartContextProvider>
                <App />
              </CartContextProvider>
            </AlertProvider>
          </Suspense>
        </ThemeProvider>
      </ApolloProvider>
    </React.StrictMode>,
    shopElement
  );
}

const shopPreviewElement = document.getElementById('shop_preview');

if (shopPreviewElement){
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Suspense fallback="loading">
            <AlertProvider template={AlertTemplate} {...alertOptions}>
              <CartContextProvider>
                <SelectCar />
              </CartContextProvider>
            </AlertProvider>
          </Suspense>
        </ThemeProvider>
      </ApolloProvider>
    </React.StrictMode>,
    shopPreviewElement
  );
}

if (module.hot) {
  module.hot.accept();
}
