import { gql } from '@apollo/client';

export const BASICS_QUERY = gql`
  query ConfiguratorQuery {
    carsignBankAccount
    
    cashOnDelivery

    countries {
      name
      countryCode
      shipping {
        name
        priceWithCarsign
        priceWithoutCarsign
      }
    }

    defaultDeliveryTime {
      displayName
      lengthInDays
    }

    mediaHost

    shippings {
      name
      priceWithCarsign
      priceWithoutCarsign
    }
  }
`;

export const CARS_QUERY = gql`
  query CarManufacturers {
    carManufacturers {
      name
      slug
      images {
        image
      }
    }

    defaultCarManufacturer {
      name
      slug
      images {
        image
      }
    }

    linkShop
    
    mediaHost
  }
`;

export const CONFIGURATOR_QUERY = gql`
  query ConfiguratorQuery {
    carManufacturers {
      name
      slug
      images {
        image
      }
    }

    engravingPrice
    engravingExtra
    
    fonts {
      id
      name
      fontFamily
      fontWeight
      fontStyle
    }
    
    individualInlayPrice

    inlayArts {
      id
      name
      inlayArtImageWhite
      inlayArtImageBlack
    }
    
    inlayBackgrounds {
      id
      name
      inlayBackgroundImage
    }
    
    inlayCategories {
      id
      name
      inlays {
        id
        name
        inlayImage
      }
    }
    
    setDiscount

    showFront
    showRear

    standardInlayPrice

    surfaces {
      id
      name
      customColor
      engravingPossible
      frameEngravingPreviewImage
      frameEngravingTextColor
      frameImage
      framePreviewImage
      frameWithInlayImage
      price
      isAvailable
      deliveryTime {
        displayName
        lengthInDays
      }
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query ProductsQuery {
    products {
      id
      isVoucher
      name
      description
      productImage
      price
      deliveryTime {
        displayName
        lengthInDays
      }
    }
  }
`;

export const VOUCHER_QUERY = gql`
  query VoucherQuery($code: String) {
    validateVoucher(code: $code) {
      amount
      code
    }
  }
`;


// export const CARS_QUERY = gql`
//   query ProductBySlug($slug: String!) {
//     productBySlug(slug: $slug) {
//       id
//       slug
//       price
      
// `;