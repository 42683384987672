import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  loadingWrapperAbsolute: {
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  message: {
    marginTop: 60,
  },
}));

function Loader(props) {
  const classes = useStyles();
  const { message, position} = props;

  return (
    <div
      className={
        position === 'absolute'
          ? classes.loadingWrapperAbsolute
          : classes.loadingWrapper
      }
    >
      <CircularProgress size={50} />
      {message && (
        <Typography variant="h2" gutterBottom className={classes.message}>
          {message}
        </Typography>
      )}
    </div>
  );
};

export default Loader;
