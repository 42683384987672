import React, { useEffect, useState } from 'react';

const LazyImage = (props) => {
    const [src, setSrc] = useState(props.src)

    useEffect(() => {
    if (props.src !== src) {
        setSrc(props.placeholder);
        const imageLoader = new Image();
        imageLoader.onload = () => {
            setSrc(props.src);
        };
        
        imageLoader.src = props.src;
    }
    }, [
        src, 
        props.src, 
        props.placeholder
    ]);
    
    return <img src={src || props.placeholder} alt={props.alt} className={props.className} />;
}

export default LazyImage;