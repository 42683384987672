const Storage = (cartItems) => {
  localStorage.setItem(
    'carsign_cart',
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

export const sumItems = (cartItems, defaultDeliveryTime) => {
  Storage(cartItems);
  const itemCount = cartItems.reduce(
    (total, product) => total + product.quantity,
    0
  );
  const total = cartItems
    .reduce((total, product) => total + product.price * product.quantity, 0)
    .toFixed(2);

  let deliveryTimeDisplayName;
  let dt = -1;
  cartItems.forEach((item) => {
    if (item.deliveryTime && item.deliveryTime.lengthInDays > dt) {
      deliveryTimeDisplayName = item.deliveryTime.displayName;
      dt = item.deliveryTime.lengthInDays;
    } else if (defaultDeliveryTime.lengthInDays > dt) {
      deliveryTimeDisplayName = defaultDeliveryTime.displayName;
      dt = defaultDeliveryTime.lengthInDays;
    }
    if (dt < 0) {
      deliveryTimeDisplayName = defaultDeliveryTime.displayName;
    }
  })

  return { itemCount, total, deliveryTimeDisplayName };
};

export const CartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      if (state.cartItems.find((item) => item.idString === action.payload.idString)) {
        const cartItem = state.cartItems.find(
          (item) => item.idString === action.payload.idString
        );
        cartItem.quantity =
          parseInt(cartItem.quantity, 10) +
          parseInt(action.payload.quantity, 10);
      } else {
        state.cartItems.push({
          ...action.payload,
          quantity: parseInt(action.payload.quantity, 10),
        });
      }
      return {
        ...state,
        ...sumItems(state.cartItems, state.defaultDeliveryTime),
        cartItems: [...state.cartItems],
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        ...sumItems(
          state.cartItems.filter((item) => item.idString !== action.payload.idString),
          state.defaultDeliveryTime,
        ),
        cartItems: [
          ...state.cartItems.filter((item) => item.idString !== action.payload.idString),
        ],
      };
    case 'INCREASE':
      state.cartItems[
        state.cartItems.findIndex((item) => item.idString === action.payload.idString)
      ].quantity++;
      return {
        ...state,
        ...sumItems(state.cartItems, state.defaultDeliveryTime),
        cartItems: [...state.cartItems],
      };
    case 'DECREASE':
      state.cartItems[
        state.cartItems.findIndex((item) => item.idString === action.payload.idString)
      ].quantity--;
      return {
        ...state,
        ...sumItems(state.cartItems, state.defaultDeliveryTime),
        cartItems: [...state.cartItems],
      };
    case 'CHECKOUT':
      return {
        cartItems: [],
        checkout: true,
        ...sumItems([], state.defaultDeliveryTime),
      };
    case 'CLEAR':
      return {
        ...state,
        validVoucherCode: '',
        voucherAmount: 0,
        cartItems: [],
        ...sumItems([], state.defaultDeliveryTime),
      };
    case 'OPEN':
      return {
        ...state,
        cartDrawerIsOpen: true,
      };
    case 'CLOSE':
      return {
        ...state,
        cartDrawerIsOpen: false,
      };
    case 'SET_COUNTRIES':
      return {
        ...state,
        countries: [...action.payload],
      };
    case 'SET_CARSIGN_BANK_ACCOUNT':
      return {
        ...state,
        carsignBankAccount: action.payload,
      };
    case 'SET_CASH_ON_DELIVERY':
      return {
        ...state,
        cashOnDelivery: action.payload,
      };
    case 'SET_DEFAULT_DELIVERY_TIME':
      return {
        ...state,
        defaultDeliveryTime: {
          displayName: action.payload.displayName,
          lengthInDays: action.payload.lengthInDays,
        },
      };
    case 'SET_MEDIA_HOST':
      return {
        ...state,
        mediaHost: action.payload,
      };
    case 'SET_SHIPPINGS':
      return {
        ...state,
        shippings: [...action.payload],
      };
    case 'SET_VOUCHER':
      return {
        ...state,
        validVoucherCode: action.payload.validCode,
        voucherAmount: action.payload.amount,
      };
    default:
      return state;
  }
};
