import React, { useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

import Drawer from './Drawer';

import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  activeCheckbox: {
    marginBottom: 40,
  },

  formControlCategory: {
    marginBottom: 30,
    marginTop: 30,
    minHeight:48,
  },

  inlay: {
    '& img': {
      maxWidth: '100%',
    },
  },

  slectedListItem: {
    backgroundColor: `${theme.colors.orange} !important`,
  },
}));


const StandardInlayDrawer = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyle();
  const {
    extra,
    mediaHost,
    inlayCategories,
    open,
    handleClose,
    setStandardInlay,
    activeStandardInlay,
    setExtra,
  } = props;

  const [category, setCategory] = useState(inlayCategories[0]);

  const handleCatChange = (evt) => {
    const newCat = inlayCategories.find((cat) => cat.id === evt.target.value);
    setCategory(newCat);
  }
  
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      header='Inlay'
    >
      <Tooltip
        title={
          activeStandardInlay === undefined
          ? t('Wählen Sie einfach ein Inlay')
          : ''
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={extra === 'standard-inlay'}
              onChange={() => {
                if (extra === 'standard-inlay') {
                  setExtra('none');
                } else {
                  setExtra('standard-inlay');
                }
              }}
              disabled={activeStandardInlay === undefined}
              name="checkedB"
              color="primary"
            />
          }
          label={t('Inlay anwenden')}
          className={classes.activeCheckbox}
        />
      </Tooltip>

      <FormControl className={classes.formControlCategory}>
        <InputLabel htmlFor="inlayCategory">{t('Kategorie')}</InputLabel>
        <Select
          value={category.id}
          onChange={handleCatChange}
          inputProps={{
            name: 'inlayCategory',
            id: 'inlay-category',
          }}
        >
          {inlayCategories.map((cat) => {
            return (
              <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <List
        component="nav"
        aria-label="inlay categories"
      >
        {category.inlays.map((inlay) => {
          return (
            <ListItem
              button
              key={inlay.id}
              selected={activeStandardInlay && activeStandardInlay.id == inlay.id}
              onClick={(event) => {
                setStandardInlay(inlay);
                setExtra('standard-inlay');
              }}
              className={classes.inlay}
              classes={{
                selected: classes.slectedListItem,
              }}
            >
              <img
                src={`${mediaHost}${inlay.inlayImage}`}
                alt={inlay.name}
              />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default StandardInlayDrawer;
