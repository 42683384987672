import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  stepWrapper: {
    backgroundColor: '#eee',
    padding: 24,
  },

  buttonRoot: {
    width: '100%',
    margin: '15px 0',
  },

  totalPriceRoot: {
    color: theme.palette.primary.main,
  },

  deliveryLink: {
    color: '#555',
    textDecoration: 'underline',
  },

  subHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 30,
  },
}));

function Summary(props) {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();
  const { paymentType, email } = props;

  return (
    <div className={classes.stepWrapper}>
      <div className={classes.subHeader}>{t('Vielen Dank für Ihre Bestellung!')}</div>

      <div>
      {t('Ihre Bestellung wird umgehend bearbeitet')}.
        <br />
        <br />
        {t('Eine Zusammenfassung Ihrer Bestellung senden wir an')}:
        {' '}
        {email}
      </div>
    </div>
  );
}

export default Summary;
