import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/styles';
import { useAlert } from 'react-alert';

import Product from './Product';

import { CartContext } from '../contexts/CartContext';
import { PRODUCTS_QUERY } from '../lib/queries';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  errorPaper: {
    backgroundColor: theme.palette.error.light,
    padding: 20,
  },

  headline: {
    color: theme.colors.orange,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 60,
  },

  productsWrapper: {
    marginTop:30,
  },
}));

const Products = () => {
  const { t, i18n } = useTranslation(['translation']);
  const alert = useAlert();
  const classes = useStyles();
  const { loading, error, data } = useQuery(PRODUCTS_QUERY);
  const { mediaHost } = useContext(CartContext);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (loading === false && data) {
        setProducts(data.products);
    }
  }, [loading, data]);

  if (error) {
    return (
      <Paper elevation={3} className={classes.errorPaper}>
        <h1>{t('Fehler')}</h1>
        <p>{t('Die Produkt-Daten konnten leider nicht geladen werden')}.</p>
        <p>{t('Bitte versuchen Sie es später erneut')}.</p>
      </Paper>
    );
  }

  if (products) {
    return (
      <>
        <div className={classes.headline}>
        {t('Extras')}
        </div>
        <Grid container spacing={3} className={classes.productsWrapper}>
          {products.map((product) => {
            return (
              <Product
                key={product.id}
                description={product.description}
                id={product.id}
                deliveryTime={product.deliveryTime ? product.deliveryTime : undefined}
                isVoucher={product.isVoucher}
                mediaHost={mediaHost}
                name={product.name}
                price={product.price}
                productImage={product.productImage}
              />
            )
          })}
        </Grid>
      </>
    );
  }
  return <LinearProgress />
}

export default Products;
