import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import { CartContext } from '../contexts/CartContext';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginBottom: 30,
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',

    '& span': {
      flexGrow: 1,
      boxSizing: 'border-box',
    },
  },

  containerSwitch: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20,

    '& span': {
      flexGrow: 0,
    },
  },

  input: {
    flex: 1,
  },

  formControl: {
    width: '100%',
  },

  radioControl: {
    marginTop: 30,
    marginBottom: 10,
  },

  subHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 30,
  },
}));

function AddressForm(props) {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();
  const {
    handleChange,
    handleBillingChange,
    shippingCompany,
    shippingSalutation,
    shippingTitle,
    shippingFirstName,
    shippingLastName,
    shippingLine1,
    shippingLine1a,
    shippingLine2,
    shippingZipCode,
    shippingCity,
    shippingCountry,

    phone,
    email,

    billingCompany,
    billingSalutation,
    billingTitle,
    billingFirstName,
    billingLastName,
    billingLine1,
    billingLine1a,
    billingLine2,
    billingZipCode,
    billingCity,
    billingCountry,
    identicalShippingAddress,

    errorBillingFirstName,
    errorBillingLastName,
    errorBillingLine1,
    errorBillingLine1a,
    errorBillingZipCode,
    errorBillingCity,
    errorEmail,
    errorShippingFirstName,
    errorShippingLastName,
    errorShippingLine1,
    errorShippingLine1a,
    errorShippingZipCode,
    errorShippingCity,
  } = props;

  const { countries } = useContext(CartContext);

  const handleFieldChange = (name) => (event) => {
    handleChange(name, event.target.value);
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <div
        className={classes.formWrapper}
        style={{ paddingRight: 10, flexGrow: 1 }}
      >
        <div className={classes.container}>
          <div className={classes.subHeader}>{t('Rechnungsadresse')}:</div>
        </div>

        <div className={classes.container}>
          <TextField
            id="billing-company-input"
            label={t("Firma")}
            value={billingCompany}
            onChange={handleFieldChange('billingCompany')}
            className={classes.input}
            margin="normal"
          />
        </div>

        <div className={classes.container}>
          <FormControl component="fieldset" className={classes.radioControl}>
            <FormLabel component="legend">{t('Anrede')}</FormLabel>
            <RadioGroup
              row
              aria-label="billing-salutation"
              name="billing-salutation-input"
              defaultValue="F"
              value={billingSalutation}
              onChange={handleFieldChange('billingSalutation')}
              margin="normal"
            >
              <FormControlLabel
                value="F"
                control={<Radio color="primary" />}
                label={t("Frau")}
              />
              <FormControlLabel
                value="M"
                control={<Radio color="primary" />}
                label={t("Herr")}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={classes.container}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">{t('Titel')}</InputLabel>
            <Select
              native
              value={billingTitle}
              onChange={handleFieldChange('billingTitle')}
              inputProps={{
                name: 'billingTitle',
                id: 'billing-title',
              }}
            >
              <option aria-label="None" value="" />
              <option value="D">{t('dr', 'Dr.')}</option>
              <option value="DM">{t('dr_med', 'Dr. med.')}</option>
              <option value="DD">{t('dr_dr', 'Dr. Dr.')}</option>
              <option value="P">{t('prof', 'Prof.')}</option>
              <option value="PD">{t('prof_dr', 'Prof. Dr.')}</option>
              <option value="PDD">{t('prof_dr_dr', 'Prof. Dr. Dr.')}</option>
            </Select>
          </FormControl>
        </div>

        <div className={classes.container}>
          <TextField
            id="billing-first-name-input"
            label={t('Vorname')}
            value={billingFirstName}
            onChange={handleFieldChange('billingFirstName')}
            className={classes.input}
            margin="normal"
            error={errorBillingFirstName}
            helperText={errorBillingFirstName ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
          />

          <TextField
            id="billing-last-name-input"
            label={t('Nachname')}
            value={billingLastName}
            onChange={handleFieldChange('billingLastName')}
            className={classes.input}
            margin="normal"
            error={errorBillingLastName}
            helperText={errorBillingLastName ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
          />
        </div>

        <div className={classes.container}>
          <TextField
            id="billing-line1-input"
            label={t('Straße')}
            value={billingLine1}
            onChange={handleFieldChange('billingLine1')}
            className={classes.input}
            margin="normal"
            error={errorBillingLine1}
            helperText={errorBillingLine1 ? t('street_error', 'Darf nicht leer sein und soll nur aus Buchstaben bestehen. Hausnummer extra angeben.') : ''}
          />

          <TextField
            id="billing-line1a-input"
            label={t('Hausnummer')}
            value={billingLine1a}
            onChange={handleFieldChange('billingLine1a')}
            className={classes.input}
            margin="normal"
            error={errorBillingLine1a}
            helperText={errorBillingLine1a ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
          />
        </div>

        <div className={classes.container}>
          <TextField
            id="billing-line2-input"
            label={t('Adresszusatz')}
            value={billingLine2}
            onChange={handleFieldChange('billingLine2')}
            className={classes.input}
            margin="normal"
          />
        </div>

        <div className={classes.container}>
          <TextField
            id="billing-zip-code-input"
            label={t('PLZ')}
            value={billingZipCode}
            onChange={handleFieldChange('billingZipCode')}
            className={classes.input}
            margin="normal"
            error={errorBillingZipCode}
            helperText={errorBillingZipCode ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
          />

          <TextField
            id="billing-city-input"
            label={t('Ort')}
            value={billingCity}
            onChange={handleFieldChange('billingCity')}
            className={classes.input}
            margin="normal"
            error={errorBillingCity}
            helperText={errorBillingCity ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
          />
        </div>

        <div className={classes.container}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="billing-country">{t('Land')}</InputLabel>
            <Select
              value={billingCountry}
              onChange={handleFieldChange('billingCountry')}
              inputProps={{
                name: 'billingCountry',
                id: 'billing-country',
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.countryCode} value={country.countryCode}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={classes.container}>
          <TextField
            id="phone-input"
            label={t('Telefon')}
            value={phone}
            onChange={handleFieldChange('phone')}
            className={classes.input}
            margin="normal"
          />
        </div>

        <div className={classes.container}>
          <TextField
            id="email-input"
            label={t('Email')}
            type="email"
            name="email"
            autoComplete="email"
            onChange={handleFieldChange('email')}
            className={classes.input}
            margin="normal"
            value={email}
            error={errorEmail}
            helperText={errorEmail ? t('error_email', 'Keine gültige Emailadresse.') : ''}
          />
        </div>

        <div className={classes.containerSwitch}>
          <FormControlLabel
            control={(
              <Switch
                checked={identicalShippingAddress}
                onChange={handleBillingChange}
                aria-label="Collapse"
                color="primary"
              />
            )}
            label={t('Die Versandadresse ist identisch mit der Rechnungsadresse')}
          />
        </div>
      </div>

      <Collapse
        in={!identicalShippingAddress}
        style={{ paddingLeft: 10, flexGrow: 1 }}
      >
        <div className={classes.formWrapper}>
          <div className={classes.container}>
            <div className={classes.subHeader}>{t('Lieferadresse')}:</div>
          </div>

          <div className={classes.container}>
            <TextField
              id="shipping-company-input"
              label={t('Firma')}
              value={shippingCompany}
              onChange={handleFieldChange('shippingCompany')}
              className={classes.input}
              margin="normal"
            />
          </div>

          <div className={classes.container}>
            <FormControl component="fieldset" className={classes.radioControl}>
              <FormLabel component="legend">{t('Anrede')}</FormLabel>
              <RadioGroup
                row
                aria-label="shipping-salutation"
                name="shipping-salutation-input"
                defaultValue="F"
                value={shippingSalutation}
                onChange={handleFieldChange('shippingSalutation')}
                margin="normal"
              >
                <FormControlLabel
                  value="F"
                  control={<Radio color="primary" />}
                  label={t('Frau')}
                />
                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label={t('Herr')}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">{t('Titel')}</InputLabel>
              <Select
                native
                value={shippingTitle}
                onChange={handleFieldChange('shippingTitle')}
                inputProps={{
                  name: 'shippingTitle',
                  id: 'shipping-title',
                }}
              >
                <option aria-label="None" value="" />
                <option value="D">{t('dr', 'Dr.')}</option>
                <option value="DM">{t('dr_med', 'Dr. med.')}</option>
                <option value="DD">{t('dr_dr', 'Dr. Dr.')}</option>
                <option value="P">{t('prof', 'Prof.')}</option>
                <option value="PD">{t('prof_dr', 'Prof. Dr.')}</option>
                <option value="PDD">{t('prof_dr_dr', 'Prof. Dr. Dr.')}</option>
              </Select>
            </FormControl>
          </div>

          <div className={classes.container}>
            <TextField
              id="shipping-first-name-input"
              label={t('Vorname')}
              value={shippingFirstName}
              onChange={handleFieldChange('shippingFirstName')}
              className={classes.input}
              margin="normal"
              error={errorShippingFirstName}
              helperText={errorShippingFirstName ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
            />

            <TextField
              id="shipping-last-name-input"
              label={t('Nachname')}
              value={shippingLastName}
              onChange={handleFieldChange('shippingLastName')}
              className={classes.input}
              margin="normal"
              error={errorShippingLastName}
              helperText={errorShippingLastName ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
            />
          </div>

          <div className={classes.container}>
            <TextField
              id="shipping-line1-input"
              label={t('Straße')}
              value={shippingLine1}
              onChange={handleFieldChange('shippingLine1')}
              className={classes.input}
              margin="normal"
              error={errorShippingLine1}
              helperText={errorShippingLine1 ? t('street_error', 'Darf nicht leer sein und soll nur aus Buchstaben bestehen. Hausnummer extra angeben.') : ''}
            />

            <TextField
              id="shipping-line1a-input"
              label={t('Hausnummer')}
              value={shippingLine1a}
              onChange={handleFieldChange('shippingLine1a')}
              className={classes.input}
              margin="normal"
              error={errorShippingLine1a}
              helperText={errorShippingLine1a ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
            />
          </div>

          <div className={classes.container}>
            <TextField
              id="shipping-line2-input"
              label={t('Adresszusatz')}
              value={shippingLine2}
              onChange={handleFieldChange('shippingLine2')}
              className={classes.input}
              margin="normal"
            />
          </div>

          <div className={classes.container}>
            <TextField
              id="shipping-zip-code-input"
              label={t('PLZ')}
              value={shippingZipCode}
              onChange={handleFieldChange('shippingZipCode')}
              className={classes.input}
              margin="normal"
              error={errorShippingZipCode}
              helperText={errorShippingZipCode ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
            />

            <TextField
              id="shipping-city-input"
              label={t('Ort')}
              value={shippingCity}
              onChange={handleFieldChange('shippingCity')}
              className={classes.input}
              margin="normal"
              error={errorShippingCity}
              helperText={errorShippingCity ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
            />
          </div>

          <div className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="shipping-country">t('Land')}</InputLabel>
              <Select
                value={shippingCountry}
                onChange={handleFieldChange('shippingCountry')}
                inputProps={{
                  name: 'shippingCountry',
                  id: 'shipping-country',
                }}
              >
                {countries.map((country) => (
                  <MenuItem
                    key={country.countryCode}
                    value={country.countryCode}
                  >
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Collapse>
    </form>
  );
}

export default AddressForm;
