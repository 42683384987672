// see:
// https://github.com/AlexSegen/react-shopping-cart
// https://dev.to/aryclenio/how-to-use-the-react-context-api-4dle

import React, { createContext, useReducer } from 'react';
import { CartReducer, sumItems } from './CartReducer';

export const CartContext = createContext();

const storage = localStorage.getItem('carsign_cart')
  ? JSON.parse(localStorage.getItem('carsign_cart'))
  : [];

const initialState = {
  cartItems: storage,
  ...sumItems(
    storage,
    {
      displayName: '10-14 Tage',
      lengthInDays: 14,
    }
  ),
  carsignBankAccount: '',
  cartDrawerIsOpen: false,
  cashOnDelivery: 2.0,
  checkout: false,
  countries: [],
  defaultDeliveryTime: {
    displayName: '10-14 Tage',
    lengthInDays: 14,
  },
  mediaHost: '',
  shippings: [],
  validVoucherCode: '',
  voucherAmount: 0,
};

const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, initialState);

  const increaseProduct = (payload) => {
    dispatch({ type: 'INCREASE', payload });
  };

  const decreaseProduct = (payload) => {
    dispatch({ type: 'DECREASE', payload });
  };

  const addProduct = (payload) => {
    dispatch({ type: 'ADD_ITEM', payload });
  };

  const removeProduct = (payload) => {
    dispatch({ type: 'REMOVE_ITEM', payload });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR' });
  };

  const handleCheckout = () => {
    dispatch({ type: 'CHECKOUT' });
  };

  const openCartDrawer = () => {
    dispatch({ type: 'OPEN' });
  };

  const closeCartDrawer = () => {
    dispatch({ type: 'CLOSE' });
  };

  const setCarsignBankAccount = (payload) => {
    dispatch({type: 'SET_CARSIGN_BANK_ACCOUNT', payload});
  }

  const setCashOnDelivery = (payload) => {
    dispatch({type: 'SET_CASH_ON_DELIVERY', payload});
  }
  
  const setDefaultDeliveryTime = (payload) => {
    dispatch({type: 'SET_DEFAULT_DELIVERY_TIME', payload});
  }

  const setCountries = (payload) => {
    dispatch({ type: 'SET_COUNTRIES', payload });
  };

  const setMediaHost = (payload) => {
    dispatch({ type: 'SET_MEDIA_HOST', payload });
  };

  const setShippings = (payload) => {
    dispatch({ type: 'SET_SHIPPINGS', payload });
  };

  const setVoucher = (payload) => {
    dispatch({type: 'SET_VOUCHER', payload});
  }

  const contextValues = {
    removeProduct,
    addProduct,
    clearCart,
    closeCartDrawer,
    decreaseProduct,
    handleCheckout,
    increaseProduct,
    openCartDrawer,
    setCountries,
    setCarsignBankAccount,
    setCashOnDelivery,
    setDefaultDeliveryTime,
    setMediaHost,
    setShippings,
    setVoucher,
    ...state,
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
