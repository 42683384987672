import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';

import { CartContext } from '../contexts/CartContext';
import { localeProps } from '../lib/utils';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  accountWrapper: {
    paddingLeft: 30,
    paddingBottom: 60,
  },

  formWrapper: {
    marginBottom: 30,
  },

  container: {
    marginTop:30,
  },

  containerSwitch: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20,

    '& span': {
      flexGrow: 0,
    },
  },

  input: {
    flex: 1,
  },

  formControl: {
    width: '100%',
  },

  subHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 30,
  },
}));

function PaymentForm(props) {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();
  const {
    country,
    handleChange,
    paymentType,

    handleAccountChange,

    accountOwner,
    accountBank,
    accountIban,
    accountBic,

    errorAccountOwner,
    errorAccountBank,
    errorAccountIban,
    errorAccountBic,
  } = props;

  const { cashOnDelivery } = useContext(CartContext);

  const handleFieldChange = (name) => (event) => {
    handleAccountChange(name, event.target.value);
  };

  const NNText = t('Per Nachnahme')
  const NNFeeText = t('nn_fee_extra', 'zzgl. Nachnahmegebühr')

  if (paymentType === 'UN') {
    return (
      <p>
        {t('no_payment_type_needed', 'Die Auswahl des Zahlungsmittels ist nicht nötig, da der Gesamtbetrag inkl. Versandkosten 0€ beträgt.')}
      </p>
    );
  } else {
    return (
      <form className={classes.container} noValidate autoComplete="off">  
        <FormControl component="fieldset">
          <FormLabel component="legend">{t('Wählen Sie Ihre bevorzugte Zahlungsart')}:</FormLabel>
          <RadioGroup
            aria-label="paymentType"
            name="paymentType"
            value={paymentType}
            onChange={handleChange}
          >
            <FormControlLabel value='PP' control={<Radio color="primary" />} label={t('PayPal')} />
            {country === 'DE' && (
              <FormControlLabel
                value='NN'
                control={<Radio color="primary" />}
                label={`${NNText} (${NNFeeText} ${Number(cashOnDelivery).toLocaleString('de', localeProps)})`}
              />
            )}
            <FormControlLabel value='VK' control={<Radio color="primary" />} label={t('Per Vorkasse')} />
            {/* {country === 'DE' && (
              <FormControlLabel value='LS' control={<Radio color="primary" />} label={t('Per Lastschrift')} />
            )} */}
          </RadioGroup>
        </FormControl>

        {paymentType === 'LS' && (
          <div className={classes.accountWrapper}>
            <TextField
              id="account-owner-input"
              label={t('Kontoinhaber')}
              value={accountOwner}
              onChange={handleFieldChange('accountOwner')}
              className={classes.input}
              margin="normal"
              fullWidth
              error={errorAccountOwner}
              helperText={errorAccountOwner ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
            />
            <TextField
              id="account-owner-input"
              label={t('Bank')}
              value={accountBank}
              onChange={handleFieldChange('accountBank')}
              className={classes.input}
              margin="normal"
              fullWidth
              error={errorAccountBank}
              helperText={errorAccountBank ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
            />
            <TextField
              id="account-owner-input"
              label={t('IBAN')}
              value={accountIban}
              onChange={handleFieldChange('accountIban')}
              className={classes.input}
              margin="normal"
              fullWidth
              error={errorAccountIban}
              helperText={errorAccountIban ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
            />
            <TextField
              id="account-owner-input"
              label={t('BIC/Swift')}
              value={accountBic}
              onChange={handleFieldChange('accountBic')}
              className={classes.input}
              margin="normal"
              fullWidth
              error={errorAccountBic}
              helperText={errorAccountBic ? t('must_not_be_empty', 'Darf nicht leer sein.') : ''}
            />
          </div>
        )}
      </form>
    )
  }
}

export default PaymentForm;
