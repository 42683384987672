import React, { useContext, useState } from 'react';
import { useApolloClient } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/Delete';

import Frame from './Frame';
import ShippingDialog from './ShippingDialog';

import { CartContext } from '../contexts/CartContext';

import { VOUCHER_QUERY } from '../lib/queries';
import { localeProps } from '../lib/utils';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    width: '100%',
    margin: '15px 0',
  },

  deliveryLink: {
    color: '#555',
    textDecoration: 'underline',
  },

  frameHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },

  frameHeaderWrapper: {
    paddingBottom: 10,
  },

  frameWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  subHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 30,
  },

  subTotalPriceRoot: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: 30,
  },

  summary: {
    textAlign: 'right',
  },

  totalPriceRoot: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: 30,
  },

  voucherButton: {
    marginBottom: 8,
    marginLeft: 10,
  },

  voucherWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
}));

function CartForm(props) {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();

  const {
    setInfoMessageLevel,
    setInfoMessageContent,
    setOpenInfoMessage,
  } = props;

  const {
    cartItems,
    deliveryTimeDisplayName,
    mediaHost,
    removeProduct,
    setVoucher,
    total,
    voucherAmount,
    validVoucherCode,
  } = useContext(CartContext);

  const client = useApolloClient();

  const [shippingDialogOpen, setShippingDialogOpen] = useState(false);
  const [voucherCode, setVoucherCode] = useState(undefined);

  const submitVoucher = () => {
    client.query({
      query: VOUCHER_QUERY,
      variables: {
        code: voucherCode,
      },
    })
    .then((res) => {
      if (res && res.data && res.data.validateVoucher) {
        setVoucher({
          validCode: res.data.validateVoucher.code,
          amount: res.data.validateVoucher.amount,
        });
      } else {
        setVoucher({
          validCode: '',
          amount: 0,
        });
        setInfoMessageLevel('error');
        setInfoMessageContent(
            <div>
              <h3>{t('Code ungültig')}</h3>
            </div>
        );
        setOpenInfoMessage(true);
      }
    })
  };

  return (
    <div>
      <div className={classes.subHeader}>{t('Ihr Warenkorb')}</div>

      <List>
        {cartItems.length === 0 && (
          <div>
            <ListItem>
              <ListItemIcon className={classes.noProducts}>
                <SentimentVeryDissatisfiedIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('no_products_in_cart', 'Sie haben leider noch kein Produkte im Warenkorb...')}
                secondary={t('Let‘s go shopping!')}
              />
            </ListItem>
            <Divider />
          </div>
        )}

        {cartItems.map((cartItem) => (
          <div key={cartItem.id}>
            <ListItem>
              <div>
                <ListItemText
                  primary={`${cartItem.quantity}x ${cartItem.name}`}
                  secondary={`(je ${Number(cartItem.price).toLocaleString('de', localeProps)}) ${Number(cartItem.price * cartItem.quantity).toLocaleString('de', localeProps)}`}
                  secondary={`(je ${Number(cartItem.price).toLocaleString('de', localeProps)}${cartItem.orgPrice && cartItem.orgPrice !== cartItem.price ? ` statt ${Number(cartItem.orgPrice).toLocaleString('de', localeProps)}` : ''}) ${Number(cartItem.price * cartItem.quantity).toLocaleString('de', localeProps)}`}
                />
                {cartItem.type === 'carsign' && cartItem.front.checked && (
                  <div className={classes.frameWrapper}>
                    <div className={classes.frameHeaderWrapper}>
                      <span className={classes.frameHeader}>{t('Fahrzeug-Front')}</span>
                      &nbsp;({cartItem.front.surface.name}
                      {cartItem.front.surface.customColor && (
                        <>
                          &nbsp;/ {t('Farbcode')}: {cartItem.front.customColorCode}
                        </>
                      )}
                      )
                    </div>
                    <Frame
                      mediaHost={mediaHost}
                      engraving={cartItem.extraData.fullData}
                      extra={cartItem.front.extra}
                      individualInlay={undefined}
                      standardInlay={undefined}
                      surface={cartItem.front.surface}
                    />
                  </div>
                )}
                {cartItem.type === 'carsign' && cartItem.rear && cartItem.rear.checked && (
                  <div className={classes.frameWrapper}>
                    <div className={classes.frameHeaderWrapper}>
                      <span className={classes.frameHeader}>{t('Fahrzeug-Heck')}</span>
                      &nbsp;({cartItem.rear.surface.name}
                      {cartItem.rear.surface.customColor && (
                        <>
                          &nbsp;/ {t('Farbcode')}: {cartItem.rear.customColorCode}
                        </>
                      )}
                      )
                    </div>
                    <Frame
                      mediaHost={mediaHost}
                      engraving={cartItem.extraData.fullData}
                      extra={cartItem.rear.extra}
                      individualInlay={cartItem.extraData.fullData}
                      standardInlay={cartItem.extraData.fullData}
                      surface={cartItem.rear.surface}
                    />
                  </div>
                )}
              </div>
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Delete"
                  onClick={() => {removeProduct(cartItem)}}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <Divider />
          </div>
        ))}
      </List>

      <div className={classes.voucherWrapper}>
        <TextField
          id="voucher-code-input"
          label="Gutschein-Code"
          value={voucherCode}
          onChange={(evt) => setVoucherCode(evt.target.value)}
          className={classes.input}
          margin="normal"
        />

        <Button
          variant="outlined"
          color="primary"
          onClick={submitVoucher}
          disabled={voucherCode === ''}
          className={classes.voucherButton}
        >
          {t('Jetzt einlösen')}
        </Button>
      </div>

      <div className={classes.summary}>
        {voucherAmount > 0 && (
          <>
            <div className={classes.subTotalPriceRoot}>
              <span>{t('Zwischensumme')}: </span>
              {Number(total).toLocaleString('de', localeProps)}
            </div>
            <div className={classes.subTotalPriceRoot}>
              <IconButton
                  aria-label="Delete"
                  onClick={() => {
                    setVoucher({
                      validCode: '',
                      amount: 0,
                    });
                  }}
                >
                  <DeleteIcon />
              </IconButton>
              <span>{t('Gutschein')} ({validVoucherCode}): </span>
              - {Number(voucherAmount).toLocaleString('de', localeProps)}
            </div>
          </>
        )}
        <div className={classes.totalPriceRoot}>
          <span>{t('Gesamtsumme')}: </span>
          {Number(total - voucherAmount > 0 ? total - voucherAmount : 0).toLocaleString('de', localeProps)}
        </div>

        <Typography variant="body1" gutterBottom>
          ({t('inkl_mwst', 'inkl_mwst', 'inkl. MwSt. zzgl.')} &nbsp;
          <span 
            className="inlineLink"
            onClick={() => setShippingDialogOpen(true)}
          > 
            {t('Versandkosten')}
          </span>
          )
        </Typography>

        <Typography variant="body1" gutterBottom>
          {t('Voraussichtliche Lieferzeit')}: {deliveryTimeDisplayName}
          <br />
          <br />
        </Typography>
      </div>

      <ShippingDialog
        open={shippingDialogOpen}
        handleClose={() => setShippingDialogOpen(false)}
      />
    </div>
  );
}

export default CartForm;
