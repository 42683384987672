import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import Checkout from './Checkout';
import ShippingDialog from './ShippingDialog';

import { CartContext } from '../contexts/CartContext';
import { isDev, localeProps } from '../lib/utils';

import { useTranslation } from 'react-i18next';

const ENV = isDev() ? 'sandbox' : 'production';

const CLIENT = {
  sandbox:
    'AUuKH2fls8ghpncsRl_y8UodAIN9NxTaelnHMVSwEabZDMqvTJAiM0io5W5kpMzNp4GKX7tFEcEA1Qop',
  production:
    'ASdFhOllSNHGnH40EYky0_QM1Tp0cXzBHciEBxS2wp-QIQIEp-rmvKhZPY0Tlb5OCW5XnXRclh2vf93S',
};

const payPalOptions = {
  'client-id': CLIENT[ENV],
  currency: 'EUR',
  locale: 'de_DE',
};

const useStyles = makeStyles((theme) => ({
  cartDrawerPaper: {
    width: 400,
    padding: 20,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },

  cartHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    marginBottom: 20,
  },

  checkoutButton: {
    width: '100%',
    margin: '15px 0',
  },

  totalPriceRoot: {
    color: theme.palette.primary.main,
  },

  noProducts: {
    color: theme.palette.primary.main,
  },
}));

function Cart() {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();
  const {
    cartDrawerIsOpen,
    cartItems,
    closeCartDrawer,
    deliveryTimeDisplayName,
    itemCount,
    mediaHost,
    removeProduct,
    total,
  } = useContext(CartContext);

  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [shippingDialogOpen, setShippingDialogOpen] = useState(false);

  const handleCheckout = () => {
    setCheckoutOpen(true);
    closeCartDrawer();
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={cartDrawerIsOpen}
        onClose={closeCartDrawer}
        classes={{ paper: classes.cartDrawerPaper }}
        className="shopElement"
      >
        <div>
          <div className={classes.cartHeader}>
            <Typography variant="h4" gutterBottom>
              {t('Warenkorb')}
            </Typography>
            <IconButton aria-label="Close" onClick={closeCartDrawer}>
              <CloseIcon />
            </IconButton>
          </div>

          <List>
            {itemCount === 0 && (
              <div>
                <ListItem>
                  <ListItemIcon className={classes.noProducts}>
                    <SentimentVeryDissatisfiedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('no_products_in_cart', 'Sie haben leider noch kein Produkte im Warenkorb...')}
                    secondary={t('Let‘s go shopping!')}
                  />
                </ListItem>
                <Divider />
              </div>
            )}

            {cartItems.map((cartItem) => (
              <div key={cartItem.id}>
                <ListItem>
                  <ListItemText
                    primary={`${cartItem.quantity}x ${cartItem.name}`}
                    secondary={`(je ${Number(cartItem.price).toLocaleString(
                      'de',
                      localeProps
                    )}${
                      cartItem.orgPrice && cartItem.orgPrice !== cartItem.price
                        ? ` statt ${Number(cartItem.orgPrice).toLocaleString(
                            'de',
                            localeProps
                          )}`
                        : ''
                    }) ${Number(
                      cartItem.price * cartItem.quantity
                    ).toLocaleString('de', localeProps)}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => removeProduct(cartItem)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>

                <Divider />
              </div>
            ))}
          </List>

          <Typography variant="body1" gutterBottom>
            {t('Voraussichtliche Lieferzeit')}: {deliveryTimeDisplayName}
            <br />
            <br />
          </Typography>

          <Typography variant="h5" className={classes.totalPriceRoot}>
            <span>{t('Gesamtsumme')}: </span>
            {Number(total).toLocaleString('de', localeProps)}
          </Typography>

          <Typography variant="body2" gutterBottom>
            ({t('inkl_mwst', 'inkl_mwst', 'inkl. MwSt. zzgl.')} &nbsp;
            <span 
              className="inlineLink"
              onClick={() => setShippingDialogOpen(true)}
            > 
              {t('Versandkosten')}
            </span>
            )
          </Typography>

          <Button
            variant="contained"
            color="primary"
            className={classes.checkoutButton}
            onClick={handleCheckout}
          >
            {t('Zur Kasse')}
          </Button>
          <Button onClick={closeCartDrawer} className={classes.checkoutButton}>
            {t('Weiter Shoppen')}
          </Button>
        </div>
      </Drawer>

      <PayPalScriptProvider options={payPalOptions}>
        <Checkout
          open={checkoutOpen}
          handleClose={() => setCheckoutOpen(false)}
        />
      </PayPalScriptProvider>
      <ShippingDialog
        open={shippingDialogOpen}
        handleClose={() => setShippingDialogOpen(false)}
      />
    </>
  );
}

export default Cart;
