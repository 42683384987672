import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { CartContext } from '../contexts/CartContext';

import { localeProps } from '../lib/utils';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  acceptAGBCheckBox: {
    marginRight: 20,
  },

  acceptAGBError: {
    color: theme.palette.error.main,
    paddingTop: 40,
  },

  acceptAGBWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 40,
  },

  errorColor: {
    color: theme.palette.error.main,
  },

  formWrapper: {
    marginBottom: 30,
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    margin: theme.spacing(),
  },

  subtotalPriceRoot: {
    fontSize: 18,
    marginTop: 30,
  },

  shippingPriceRoot: {
    fontSize: 16,
    marginTop: 10,
  },

  shippingHint: {
    fontSize: 12,
  },

  totalPriceRoot: {
    fontSize: 22,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: 30,
  },

  subHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 30,
  },

  summary: {
    textAlign: 'right',
  },

  card: {
    marginBottom: 40,
    height: '100%',
  },

  cardContent: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#EC7063',
    color: '#1C2833',
  },
}));

function FinishPaymentForm(props) {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();
  const { carsignBankAccount, countries, total, cartItems, deliveryTimeDisplayName, voucherAmount, validVoucherCode } = useContext(CartContext);

  const {
    handleChange,
    shippingCompany,
    shippingSalutation,
    shippingTitle,
    shippingFirstName,
    shippingLastName,
    shippingLine1,
    shippingLine1a,
    shippingLine2,
    shippingZipCode,
    shippingCity,
    shippingCountry,

    phone,
    email,

    billingCompany,
    billingSalutation,
    billingTitle,
    billingFirstName,
    billingLastName,
    billingLine1,
    billingLine1a,
    billingLine2,
    billingZipCode,
    billingCity,
    billingCountry,
    identicalShippingAddress,

    paymentType,

    accountOwner,
    accountBank,
    accountIban,
    accountBic,

    howDidYouGetHere,
    howDidYouGetHereMessage,
    message,

    acceptAGB,
    setAcceptAGB,
    acceptAGBError,

    shipping,
    shipToCountry,
  } = props;

  const paymentTypes = {
    'PP': t('PayPal Zahlung per Lastschrift oder auch Kreditkarte'),
    'VK': t('Vorkasse'),
    // 'LS': t('Lastschrift'),
    'NN': t('nn_plus_fee', 'Nachnahme (zzgl. Nachnahmegebühr 2,00 €)'),
    'UN': t('no_payment_needed', 'Keine Zahlung notwendig, da der Besamtbetrag inkl. Versandkosten 0€ beträgt'),
  }

  const salutationTypes = {
    'F': t('Frau'),
    'M': t('Herr'),
  }

  const titleTypes = {
    'D': t('dr', 'Dr.'),
    'DM': t('dr_med', 'Dr. med.'),
    'DD': t('dr_dr', 'Dr. Dr.'),
    'P': t('prof', 'Prof.'),
    'PD': t('prof_dr', 'Prof. Dr.'),
    'PDD': t('prof_dr_dr', 'Prof. Dr. Dr.'),
  }

  const handleFieldChange = (name) => (event) => {
    handleChange(name, event.target.value);
  };

  const billingCountryObj = countries.find((c) => c.countryCode === billingCountry);
  const shippingCountryObj = countries.find((c) => c.countryCode === shippingCountry);

  return (
    <div>
      <div className={classes.subHeader}>
        {t('almost_done', 'Gleich sind Sie fertig! Nur noch die Bestellung bestätigen...')}
      </div>
      <div className={classes.subHeader}>{t('Ihre Bestellung')}:</div>

      <List>
        {cartItems.map((cartItem) => (
          <div key={cartItem.id}>
            <ListItem>
              <ListItemText
                primary={`${cartItem.quantity}x ${cartItem.name}`}
                secondary={`(je ${Number(cartItem.price).toLocaleString(
                  'de',
                  localeProps
                )}${
                  cartItem.orgPrice && cartItem.orgPrice !== cartItem.price
                    ? ` statt ${Number(cartItem.orgPrice).toLocaleString(
                        'de',
                        localeProps
                      )}`
                    : ''
                }) `}
              />
              <ListItemSecondaryAction>
                {Number(cartItem.price * cartItem.quantity).toLocaleString(
                  'de',
                  localeProps
                )}
              </ListItemSecondaryAction>
            </ListItem>

            <Divider />
          </div>
        ))}
      </List>

      <div className={classes.summary}>
        <div className={classes.subtotalPriceRoot}>
          <span>{t('Zwischensumme')}: </span>
          {Number(total).toLocaleString('de', localeProps)}
        </div>

        {voucherAmount > 0 && (
          <div className={classes.subtotalPriceRoot}>
            <span>{t('Gutschein')}: {validVoucherCode}</span>
            - {Number(voucherAmount).toLocaleString('de', localeProps)}
          </div>
        )}

        <div className={classes.shippingPriceRoot}>
          <span>{t('Versandkosten')}: </span>
          {Number(shipping).toLocaleString('de', localeProps)}
          <br />
          <span className={classes.shippingHint}>
            ({t('Versand nach')} {shipToCountry.name})
          </span>
        </div>

        <div className={classes.totalPriceRoot}>
          <span>{t('Gesamtsumme')}: </span>
          {(Number(total) + Number(shipping) - Number(voucherAmount) > 0 ? Number(total) + Number(shipping) - Number(voucherAmount) : Number(0)).toLocaleString('de', localeProps)}
        </div>
      </div>

      <div className={classes.subHeader}>
        {t('Zahlungsart')}:
      </div>
      
      {paymentTypes[paymentType]}

      {paymentType === 'LS' && (
        <div className={classes.accountWrapper}>
          <br/>
          {t('Kontoinhaber')}: {accountOwner}<br/>
          {t('Bank')}: {accountBank}<br/>
          {t('IBAN')}: {accountIban}<br/>
          {t('BIC/Swift')}: {accountBic}<br/>
        </div>
      )}

      {paymentType === 'VK' && (
        <div className={classes.accountWrapper}>
          <br/>
          <p>
            {t('Bitte überweisen Sie den unten angegebenen Rechnungsbetrag unter Angabe Ihres Namen als Verwendungszweck auf die folgende Kontoverbindung')}:
          </p>
          { carsignBankAccount.split("\n").map((i,key) => {
              return <div key={key}>{i}</div>;
          })}
          <br/>
          <p>({t('Die Kontodaten erhalten Sie auch noch per Email')}.)</p>
        </div>
      )}


      <div className={classes.subHeader}>
      {t('Voraussichtliche Lieferzeit')}:
      </div>
      {deliveryTimeDisplayName}

      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className={classes.subHeader}>{t('Rechnungsadresse')}:</div>

          <div className={classes.addressBlock}>
            {billingCompany && (
              <div>{billingCompany}</div>
            )}

            <div>
              {billingSalutation && (
                <span>
                  {salutationTypes[billingSalutation]}
                  {' '}
                </span>
              )}
              {billingTitle && (
                  <span>
                    {titleTypes[billingTitle]}
                    {' '}
                  </span>
                )}
              {billingFirstName}
              {' '}
              {billingLastName}
            </div>
            <div>{billingLine1} {billingLine1a}</div>

            {billingLine2 && (
              <div>{billingLine2}</div>
            )}

            <div>
              {billingZipCode}
              {' '}
              {billingCity}
            </div>
            <div>{billingCountryObj.name}</div>
          </div>
        </Grid>

        {!identicalShippingAddress && (
          <Grid item xs={12} sm={6}>
            <div className={classes.subHeader}>
              {t('Lieferadresse')}:
            </div>

            <div className={classes.addressBlock}>
              {shippingCompany && (
                <div>{shippingCompany}</div>
              )}

              <div>
                {shippingSalutation && (
                  <span>
                    {salutationTypes[shippingSalutation]}
                    {' '}
                  </span>
                )}
                {shippingTitle && (
                  <span>
                    {titleTypes[shippingTitle]}
                    {' '}
                  </span>
                )}
                {shippingFirstName}
                {' '}
                {shippingLastName}
              </div>
              <div>{shippingLine1} {shippingLine1a}</div>

              {shippingLine2 && (
                <div>{shippingLine2}</div>
              )}

              <div>
                {shippingZipCode}
                {' '}
                {shippingCity}
              </div>
              <div>{shippingCountryObj.name}</div>
            </div>
          </Grid>
        )}
      </Grid>

      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className={classes.subHeader}>{t('Wie wurden Sie auf CarSign aufmerksam?')}</div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="howDidYouGetHere"
              name="howDidYouGetHere"
              value={howDidYouGetHere}
              onChange={handleFieldChange('howDidYouGetHere')}
            >
              <FormControlLabel value="ME" control={<Radio color="primary" />} label={t('Messe')} />
              <FormControlLabel value="SU" control={<Radio color="primary" />} label={t('Suchmaschine')} />
              <FormControlLabel value="FO" control={<Radio color="primary" />} label={t('Forum / Blog')} />
              <FormControlLabel value="IN" control={<Radio color="primary" />} label={t('Inserate')} />
              <FormControlLabel value="EM" control={<Radio color="primary" />} label={t('Empfehlung durch Freund und Bekannte')} />
              <FormControlLabel value="SO" control={<Radio color="primary" />} label={t('Sonstiges')} />
            </RadioGroup>
          </FormControl>

          {howDidYouGetHere === 'SO' && (
            <TextField
              id="standard-multiline-static"
              multiline
              label={t('Sonstiges')}
              fullWidth
              value={howDidYouGetHereMessage}
              onChange={handleFieldChange('howDidYouGetHereMessage')}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.subHeader}>{t('Ihre optionale Nachricht für CarSign')}</div>
          <TextField
            id="standard-multiline-static"
            multiline
            fullWidth
            value={message}
            onChange={handleFieldChange('message')}
          />

          {acceptAGBError && (
            <div className={classes.acceptAGBError}>
              {t('Bitte bestätigen Sie die AGB & Ihr Widerrufsrecht')}
            </div>
          )}

          <div className={classes.acceptAGBWrapper}>
            <Checkbox
              checked={acceptAGB}
              className={classes.acceptAGBCheckBox}
              color="primary"
              onChange={() => setAcceptAGB(!acceptAGB)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div className={acceptAGBError ? classes.errorColor : ''}
              dangerouslySetInnerHTML={{__html: t('agb_text', 'Hiermit bestätige ich die <a href="https://carsign.de/agb/" target="_blank"> Allgemeinen Geschäftsbedingungen der CarSign Germany GmbH</a> gelesen zu haben und diese zu akzeptieren.<br/> Von meinem Widerrufsrecht habe ich ebenfalls Kenntnis genommen.', { 'interpolation': {'escapeValue': false}} )}}>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default FinishPaymentForm;
