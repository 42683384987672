import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { useAlert } from 'react-alert';
import Select from 'react-select';

import Grid from '@material-ui/core/Grid';

import LazyImage from './LazyImage';
import Loader from './Loader';
import { CARS_QUERY } from '../lib/queries';
import { CartContext } from '../contexts/CartContext';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  configAppOuter: {
    position: 'relative',
    minHeight: 400,
  },

  configApp: {
    padding: '80px 0',
    maxWidth: 1200,
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    },
  },

  button: {
    backgroundColor: theme.colors.orange,
    color: 'white',
    padding: 20,
    display: 'inline-block',
    marginTop: 20,
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: 400,
  },

  carImage: {
    maxWidth: '100%',
  },

  headline: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 40,
    fontWeight: 400,

    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    }
  },

  manufacturerSelect: {
    margin: '30px 0 20px',

    '&:focus, &:active': {
      outline: 'none',
    }
  },

  spacer: {
    backgroundColor: theme.colors.orange,
    height: 1,
    width: 50,
    margin: '30px auto 30px',
  },
}));

const SelectCar = (props) => {
  const { t, i18n } = useTranslation(['translation']);
  const classes = useStyles();
  const alert = useAlert();
  const { loading, error, data } = useQuery(CARS_QUERY);
  const { onNext } = props;
  const { mediaHost, setMediaHost } = useContext(CartContext);
  
  const [currentImage, setCurrentImage] = useState(0);
  const [defaultManufacturer, setDefaultManufacturer] = useState(undefined);
  const [linkShop, setLinkShop] = useState(undefined);
  const [manufacturer, setManufacturer] = useState(undefined);
  const [carManufacturers, setCarManufacturers] = useState(undefined);

  let imagesInterval;
  
  useEffect(() => {
    if (loading === false && data) {
      setLinkShop(data.linkShop);
      setMediaHost(data.mediaHost);
      let defaultImages = [];
      data.defaultCarManufacturer.images.forEach((i) => {
        defaultImages.push(i.image);
      });
      const defaultM = {
        value: data.defaultCarManufacturer.slug,
        label: data.defaultCarManufacturer.name,
        images: defaultImages,
      };
      setDefaultManufacturer(defaultM);

      let newCarManufacturers = [];
      // add default manufacturer in first place
      newCarManufacturers.push(defaultM);
      // add other manufacturers
      data.carManufacturers.forEach((cm) => {
        let cmImages = [];
        if (cm.images.length > 0) {
          cm.images.forEach((i) => {
            cmImages.push(i.image);
          });
        } else {
          cmImages = defaultM.images;
        }

        newCarManufacturers.push({
          value: cm.slug,
          label: cm.name,
          images: cmImages,
        })
      }); 
      setManufacturer(newCarManufacturers[0]);
      setCarManufacturers(newCarManufacturers);
    }
  }, [loading, data]);

  const nextImage = () => {
    if (manufacturer && currentImage >= manufacturer.images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  }

  useEffect(() => {
    if (manufacturer){      
      setCurrentImage(0);
   }
  }, [manufacturer]);

  useEffect(() => {
    const imagesInterval = setInterval(nextImage, 3000);
    return () => clearInterval(imagesInterval);
  }, [currentImage]);

  const handleChange = (selectedOption) =>{
    setManufacturer(selectedOption);
  };

  if (carManufacturers && manufacturer && linkShop && mediaHost && loading === false) {
    return (
      <div className={classes.configAppOuter}>
        <div className={classes.configApp}>
          <main>
            <div className={classes.headline} dangerouslySetInnerHTML={{__html: t('order_now', '<span className="orange-text">Jetzt </span>Ihr CarSign bestellen')}} >
            </div>
            <div className={classes.spacer}></div>
            <Grid container className={classes.root} spacing={3}>
              <Grid item xs={12} sm={6} spacing={3}>
                <LazyImage
                  src={`${mediaHost}${manufacturer.images[currentImage]}`}
                  placeholder='https://via.placeholder.com/942x674?text=loading...'
                  alt={manufacturer.label}
                  className={classes.carImage}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className={classes.text}>
                  <p>
                    {t('Konfigurieren Sie Ihren CarSign Kennzeichenhalter ganz einfach selber für die Kennzeichen EU-Normgröße 520 x 110 mm')}.
                  </p>
                </div>

                <Select
                  onChange={(selectedOption) => handleChange(selectedOption)}
                  options={carManufacturers}
                  isSearchable={true}
                  isClearable={false}
                  placeholder={t('your_manufacturer', 'Ihre Fahrzeugmarke...')}
                  className={classes.manufacturerSelect}
                />

                <a
                  className={classes.button}
                  href={`${linkShop}?cm=${manufacturer.label}`}
                >
                  {t('Konfigurieren')}
                </a>
              </Grid>
            </Grid>
          </main>
        </div>
      </div>
    );
    } else {
      return <Loader position="absolute" message="Loading" />;
    }
}

export default SelectCar;
